#m-dashboard-page {
  .dashboard-title-page {
    margin-bottom: 40px;
  }
  .modal-full-container {
    width: 97%;
    z-index: 999;
  }
  #dashboard-filters {
    margin-bottom: 10px;
    display: inline-block;
    width: 100%;
    .filter-item {
      background: $bg-color-filter;
      border: 1px solid $color-border-filter;
      border-radius: 1px;
      padding: 8px 10px;
      margin: 0 10px 10px 0;
      float: left;
      display: inline-flex;
      align-items: center;
      &:hover {
        background: $bg-color-hover-filter;
        border: 1px solid $color-hover-border-filter;
      }
      span {
        font-size: 12px;
        font-weight: 700;
        line-height: 15px;
      }
      .btn {
        padding: 0;
        margin: 0 0 0 10px;
        float: left;
        display: inline-flex;
        i {
          display: flex;
          &:before {
            font-size: 15px;
            line-height: 11px;
          }
        }
      }
    }
    .separator-tag {
      border-left: 1px solid $color-border-filter;
      padding: 16px 0px;
      margin: 1px 10px 10px 0;
      float: left;
      display: inline-flex;
    }
    .clear-filters {
      background: $bg-color-filter;
      padding: 7px 14px;
      margin: 0 0 10px 0;
      font-size: 12px;
      line-height: 16px;
      float: left;
      font-weight: 700;
      color: $color-c-black;
      border: 1px solid rgba(32, 32, 32, 0.6);
      border-radius: 1px;
    }
  }
}

#dashboard-form {
  .dashboard-title {
    width: 58%;
    padding-bottom: 10px
  }

  .dashboard-name {
    padding-top: 13px;
  }

  .dashboard-description {
    padding-top: 15px;
  }

  .dashboard-quick-sight-id {
    padding-top: 13px;
  }

  .dashboard-quick-sight-id-tooltip {
    height: 10px;
    width: 10px;
    padding-bottom: 3px;
    float: right;
  }

  .dashboard-approve-button-loading-icon {
    margin-left: 10px;
  }
}

.type_icon {
  padding-bottom: 2px;
  padding-right: 10px !important;
}

#dashboard-list-management {
  &.dashboard-edit-mode {
    #head_box_name,
    .cell_box_name {
      width: 20% !important;
      min-width: 20% !important;
    }
  }
  .table-list .custom-table .custom-table-cell {
    padding: 0.8rem .75rem;
  }

  .cell_box_name {
    a {
      line-height: 16px;
    }
  }

  #head_box_name,
  .cell_box_name {
    width: 29% !important;
    min-width: 29% !important;
  }

  #head_box_productType,
  .cell_box_productType {
    width: 26% !important;
    min-width: 26% !important;
  }

  #head_box_description, .cell_box_description {
    width: 28% !important;
    min-width: 28% !important;
  }

  #head_box_updatedAt, .cell_box_updatedAt {
    width: 17% !important;
    min-width: 17% !important;
  }

  .cell_box_description {
    text-overflow: ellipsis;
    white-space: nowrap !important;
    overflow: hidden;
  }

  #head_box_action, .cell_box_action {
    width: 9% !important;
    min-width: 9% !important;
  }
}
