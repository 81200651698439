#pbm-home {
  .card-desc {
    min-height: 150px;
  }
  .align-vertical-top-10 {
    vertical-align: 10px;
  }
  .badge-pill {
    padding: 5px 15px;
  }
}

@media (max-width: 1294px) {
  #pbm-home {
    .card-desc {
      min-height: 180px;
    }
  }
}

@media (max-width: 1198px) {
  #pbm-home {
    .card-desc {
      min-height: 150px;
    }
  }
}