$color-link-ms: #1E7362;

a.new-lead-ms {
  color: $color-link-ms;
  &:hover {
    text-decoration: underline;
  }
}

.custom-tabs .tab {
  background: transparent;
  border: none;
}

.custom-tabs .tab-content {
  padding: 0;
  background: none;
  box-shadow: none;

  #customerView-tabpane-documents {
    i {
      padding: 4px;
    }
  }
}

.custom-tabs .tab-pane {
  padding: 0;
  background: none;
  box-shadow: none;
}

.custom-tabs .custom-tab {
  padding: 35px 30px 5px 30px;
  background: #fff;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.05);
}

.lead-broker-widget {
  .card {
    box-shadow: none;
    margin-bottom: 0 !important;
  }
}

.policy-object-toggle {
  background-color: #E7F1FF;
}

.policy-object-header {
  cursor: pointer;
  border-radius: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.policy-object-list > :last-child {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.header-content {
  white-space: nowrap;
}

#lead-view-page {
  .pcoded-header {
    background: $theme-background;
    min-height: 19px!important;
  }

  #lead-version-dropdown {
    z-index: auto;
    .dropdown-menu {
      padding: 0;
      margin-top: 10px;
      min-width: 230px;
      transform: translate3d(-67px, 70px, 0px);
      max-height: 389px;
      overflow-y: auto;

      .dropdown-item {
        padding: 10px 25px;

        &:hover {
          color: $primary-color;
        }
      }

      &.show {
        left: -26px!important;
        &:before {
          content: none;
          left: 90px;
        }
      }
    }
  }

  .dropdown-menu {
    &.show {
      left: -37px!important;
      &:before {
        left: 90px;
      }
    }
  }

  .bf-nav-dropdown-menu {
    &.show {
      left: 0!important;
      margin-top: 10px;
      border: 1px solid rgba(0, 0, 0, 0.15);
      box-shadow: none;
      &:before {
        display: none;
      }
    }
  }

  .dropdown-toggle.bf-nav-view {
    border-left: 1px solid $color-white;
    margin-left: 0;
    &:after {
      display: inline-block;
      margin-left: 15px;
    }
  }
}

#lead-document-list {
  .cell_box_filename {
    a {
      width: 100%;
      position: relative;
      span {
        width: 92%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  &.overflow-visible {
    .table-list,
    .infinite-list {
      overflow: visible!important;
      z-index: 1;
    }
  }
}

#lead-list-page .card {
  padding-bottom: 20px;
}

.lead-status-declined {
  background: $secondary-color;
}

.lead-status-approved {
  background: $color-approved;
}

#lead-versions-list {
  background: $theme-background;
}

@media (max-width: 600px) {
  .header-info {
    flex-basis: auto
  }

  .custom-tabs .custom-tab:nth-child(1) {
    margin-top: 20px;
    margin-right: 0;
  }
}
