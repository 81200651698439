$color-bg-icon: #E1E6F8;
$color-text-icon: #6884DA;
$color-bg-local-mode: #5CB85C;
$color-bg-dev-mode: #0055CC;
$color-bg-test-mode: #C6EDFB;
$color-bg-sandbox-mode: #E56910;

.app-mode {
  .notification-env-mode {
    position: fixed;
    min-height: 50px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1030;
    p {
      color: $color-white;
      padding: 0;
      margin: 0;
    }
  }
  .eis-sticky-top {
    top: 50px;
  }
}

.app-mode > .fullscreen {
  position: relative;
  top: 50px;
  .pcoded-navbar {
    margin-top: 50px;
  }
}

.local-mode {
  .notification-env-mode {
    background: $color-bg-local-mode;
  }
}

.dev-mode {
  .notification-env-mode {
    background: $color-bg-dev-mode;
  }
}

.test-mode {
  .notification-env-mode {
    background: $color-bg-test-mode;
    p {
      color: $color-text-ws;
    }
  }
}

.sandbox-mode {
  .notification-env-mode {
    background: $color-bg-sandbox-mode;
  }
}

/* For global search modifications */
.collapse.navbar-collapse {
  ul.navbar-nav {
    &.mr-auto {
      flex-grow: 1;
      flex-direction: row;
      align-items: center;
      display: flex;
      .nav-item {
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: auto;
      }
    }
    &.ml-auto {
      flex-grow: 0;
    }
  }
  .emil-global-search {
    line-height: 21px;
    max-width: 370px;
    .icon-search {
      background: none;
      border: none;
      cursor: pointer;
    }
    .emil-box-search__control {
      border: 1px solid $search-border-color;
      box-shadow: 0 6px 12px -5px rgba(65, 76, 78, 0.08);
      border-radius: 20px;
      background: $color-white;
      padding: 0 10px;
      -webkit-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
    }
    .emil-box-search__control--menu-is-open {
      .emil-box-search__indicators {
        .icon-search:before {
          content: "\e8f6";
        }
      }
    }
    .emil-search-option {
      align-items: center;
      display: flex;
      flex-direction: row;
      cursor: pointer;
      .option-content {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        .content-line {
          margin-bottom: 4px;
          &:last-child {
            margin-bottom: 0px;
          }
          &.alone {
            margin-bottom: 0px;
          }
        }
      }
    }
  }
}

.input-search {
  .icon-search, .input-group-text {
    background: none;
    border: none;
    cursor: pointer;
  }

  .input-group {
    max-width: 250px;
    height: 38px;
    background: $color-white;
    border: 1px solid $search-border-color;
    box-shadow: 0 6px 12px -5px rgba(65, 76, 78, 0.08);
    border-radius: 20px;

    input {
      background: transparent;
      padding: 0 15px;
      border: none;

      &:focus {
        box-shadow: none;
      }
    }
  }
}

.infinite-list {
  .progress {
    height: 8px;
  }
}

.success-background {
  background: $cyan-color;
}

.failed-background {
  background: $danger-color;
}

.text-c-black {
  color: $color-c-black;
}

.text-c-grey {
  color: $color-c-grey;
}

.text-primary-color {
  color: $primary-color;
}

.link-color {
  color: $tab-nav-color;
}

// App Formik
.form-group {
  margin-bottom: 15px;
}
.form-control-plaintext,
.app-field-plaintext {
  padding: 10px 20px;
  font-size: 14px;
  border: 1px solid $color-white;
  display: block;
  white-space: break-spaces;
}

.form-control:disabled,
.form-control[readonly] {
    pointer-events: none;
}

.form-no-bg {
  .app-field {
    .app-field-control {
      .form-control-plaintext,
      .app-field-plaintext {
        border-color: transparent;
      }
      .form-control {
        background-color: $input-bg;
      }
      .app-field-select {
        .af-select__control {
          background: $color-white;
        }
      }
    }
  }
}

.theme-text-color {
  color: $theme-font-color;
}

.date-error .input-group input,
.date-error .input-group-text {
  border-color: $form-feedback-invalid-color;
}
.date-error .input-group input:focus,
.date-error .input-group-text:focus {
  box-shadow: 0 0 0 $input-focus-width rgba($form-feedback-invalid-color, 0.25);
}

.app-field {
  &.app-field-state-changed {
    .app-field-control {
      .form-control {
        border-color: $cyan-color;
        outline: 0;
        box-shadow: 0px 0px 1px 1px $cyan-color;
      }
      .form-check {
        .form-check-input {
          background-color: $cyan-color;
        }
      }
      .twitter-picker,
      .form-control-img {
        border-color: $cyan-color !important;
        outline: 0;
        box-shadow: 0px 0px 2px 2px $cyan-color !important;
      }
    }
  }
  .app-field-control {
    .form-control {
      margin-top: 0;
    }
    .checkbox {
      input[type="checkbox"].form-control-checkbox {
        // those 3 lines below are needed for events like space key, onBlur, onClick to work...
        display: inline-block;
        position: absolute;
        opacity: 0;

        &:disabled + .form-label,  + .cr {
          padding-left: 21px;
        }
        &:focus + .form-label:before {
          border-color: $input-focus-border-color;
          box-shadow: $input-focus-box-shadow;
        }
        &.is-invalid + .form-label:before {
          border-color: $form-feedback-invalid-color;
        }
        &.is-invalid:focus + .form-label:before {
          box-shadow: 0 0 0 $input-focus-width rgba($form-feedback-invalid-color, 0.25);
        }
        &.state-new + .form-label:before {
          border-color: $input-border-color;
        }
        &.state-changed + .form-label:before {
          border-color: $cyan-color;
          box-shadow: 0px 0px 1px 1px $cyan-color;
        }
        & + .form-label:before {
          border: 1px solid $input-border-color;
          background-color: $input-bg;
          color: transparent;
        }
        &:checked + .form-label:before {
          color: $cyan-color;
        }
      }
    }
    .app-field-select {
      .af-select__control {
        background: $theme-background;
        .af-select__value-container {
          padding: 6px 8px 6px 18px;
        }
      }
      &.state-new {
        .af-select__control {
          border-color: $input-border-color;
          &.af-select__control--is-focused {
            border-color: $input-focus-border-color;
            box-shadow: $input-focus-box-shadow;
          }
        }
      }
      &.state-changed {
        .af-select__control {
          border-color: $cyan-color;
          box-shadow: 0px 0px 1px 1px $cyan-color;
        }
      }
      &.state-invalid {
        .af-select__control {
          border-color: $form-feedback-invalid-color;
          &.af-select__control--is-focused {
            box-shadow: 0 0 0 $input-focus-width rgba($form-feedback-invalid-color, 0.25);
          }
        }
      }
      .af-select__menu {
        z-index: 999;
      }
    }
  }

  .error-message {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: $danger-color;
  }

  .color-col {
    padding: 10px 20px;
  }

  .color-view {
    background-color: $color-white;
    display: inline-block;
    height: 15px;
    width: 15px;
    border-radius: 10px;
    margin-right: 5px;
  }

  .img-placeholder {
    width: 150px;
    height: 100px;
    margin-right: 10px;
    background: $color-white;
    border: 0px solid rgba($color-c-black, 0.25);
    box-shadow: rgba($color-c-black, 0.25) 0px 1px 4px;
    border-radius: 4px;
  }
}

.checkbox {
  &.readonly {
    input[type="checkbox"] {
      &:checked + .form-label:before {
        color: $cyan-color;
      }
      & + .form-label:before {
        border-color: $input-border-color;
        background-color: $input-bg;
        color: transparent;
      }
    }
  }
}

.twitter-picker {
  max-width: 280px;
  width: auto !important;
}

.btn-primary {
  background: $primary-color;
  border-color: $primary-color;

  &:hover {
    background: $primary-color-hover;
    border-color: $primary-color-hover;
  }
}

.error-message {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: $danger-color;
}

.btn-outline-primary {
  color: $primary-color;
  border-color: $primary-color;

  &:hover {
    background: $primary-color-hover;
    border-color: $primary-color-hover;
  }
}

.table td {
  color: $theme-heading-color;
}

.p-12 {
  padding: 12px 0;
}

.card-header {
  h5 {
    &:after {
      display: none;
    }
  }
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
}

.custom-chevron-down {
  right: 27px;
  position: relative;
  font-weight: 600;
  color: hsl(0,0%,80%)!important;
}

select + i.material-icons {
  float: right;
  margin-top: -32px;
  pointer-events: none;
  background-color: transparent;
  color: $color-link-setting;
}

.time-frame, .all-in-one {
  i.material-icons {
    z-index: 100;
  }
}

.popup-border-top {
  border-top: 1px solid $disabled;
}

.text-dark-gray {
  color: $theme-heading-color;
}

.chevron {
  cursor: pointer;
}

// User search component
.emil-user-search {
  line-height: 21px;
  .emil-box-user-search__control {
    border: 1px solid $user-search-border-color;
    background-color: $theme-background;
    padding: 0 0 0 10px;
    margin: 0;
    width: 100%;
    min-height: 40px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    .emil-box-user-search__placeholder {
      span {
        color: $theme-heading-color;
      }
    }
    .emil-box-user-search__value-container {
      padding-left: 0 !important;
    }
  }
  .emil-box-user-search__control--menu-is-open {
    background: $color-white;
  }

  .emil-box-user-search__value-container--has-value {
    .emil-user-search-option {
      padding: 0 !important;
    }
  }

  .emil-user-search-option {
    color: $theme-heading-color;
    align-items: center;
    flex-direction: row;
    cursor: pointer;
  }
}

// Customer/Policy search component
.emil-customer-policy-search {
  line-height: 21px;
  .icon-search {
    background: none;
    border: none;
    cursor: pointer;
  }
  .emil-customer-policy-box-search__control {
    border: 1px solid $user-search-border-color;
    background-color: $theme-background;
    padding: 0 0 0 10px;
    margin: 0;
    width: 100%;
    min-height: 43px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .emil-customer-policy-box-search__control--menu-is-open {
    .emil-customer-policy-box-search__indicators {
      .icon-search:before {
        content: "\e8f6";
      }
    }
  }
  .emil-search-option {
    align-items: center;
    display: flex;
    flex-direction: row;
    cursor: pointer;
  }
}

// Ticket Status and Category component
.select-values-list,
.ticket-status-list,
.ticket-category-list {
  line-height: 21px;
  .select-item__control {
    border: 1px solid $user-search-border-color;
    background-color: $theme-background;
    padding: 0 10px 0 20px;
    margin: 0;
    width: 100%;
    min-height: 43px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    .select-item__placeholder {
      span {
        color: $theme-heading-color;
      }
    }
    .select-item__value-container {
      padding-left: 0;
    }
  }
  .select-item__control--menu-is-open {
    background: $color-white;
  }
}

.status-color-all_unsolved {
  color: $color-status-unsolved;
}
.status-color-unassigned {
  color: $color-status-unassigned;
}
.status-color-assigned_to_me {
  color: $color-status-assigned-to-me;
}
.status-color-open {
  color: $color-status-open;
}
.status-color-blocked {
  color: $color-status-blocked;
}
.status-color-solved {
  color: $color-status-solved;
}
.status-color-waiting {
  color: $color-status-waiting;
}
.status-color-finished {
  color: $added-color;
}
.status-color-disabled {
  color: $list-thumbnail-label-color;
}

.icon-background {
  border-radius: 10px;
  width: 54px;
  height: 54px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.icon-small-background {
  border-radius: 10px;
  width: 32px;
  height: 32px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.product-icon {
  border-radius: 10px;
  width: 24px;
  height: 24px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: $color-bg-icon;
  color: $color-text-icon;
}

.table-striped>tbody>tr:nth-child(odd)>td,
.table-striped>tbody>tr:nth-child(odd)>th {
   background-color: $table-stripe;
 }

.light-gray {
  background: $menu-dark-text-color;
}

.dark-background {
  background: $brand-background;
}

.green {
  background: $added-color;
}

.light-color {
  background: $light-color;
}

.yellow-color {
  background: $warning-color;
}

.r-4 {
  border-radius: 4px;
}

#all-in-one-policy-table {
  thead th {
    border-bottom: 2px solid $theme-border;
  }

  td {
    border-top: none;
  }
}

.payment-methods-logo {
  width: 38px;
}

img.payment-methods-logo {
  cursor: pointer;
}

.provider-name {
  text-transform: capitalize;
}

.alert-info a {
  color: inherit;
}

.table-tooltip.payment-method-tooltip {
  .tooltip-inner {
    background-color:black;
    color: white;
  }

  .arrow {
    &:before {
      color: black;
    }
  }
}

#tickets-filters {
  .btn-white {
    @include button-variant-pc(#fff, #fff, $bg-color-btn-active, transparent, $bg-color-btn-active, transparent);
  }
}
#ticket-list {
  .table-list {
    padding: 10px 0;

    .cell_box_selection {
      padding: 0;
    }

    #head_box_assignedTo, .cell_box_assignedTo, #footer_box_assignedTo {
      width: 25%!important;
    }

    #head_box_subject, .cell_box_subject, #footer_box_subject {
      width: 27%!important;
    }

    .selection-label {
      display: inline-block;
      cursor: pointer;
      padding: 18px 12px;
      margin-bottom: 0;
    }
  }
}

.selection-label-hidden,
.selection-label {
  display: inline-block;
  cursor: pointer;
  padding: 0;
  margin-bottom: 0;
}

.selection-label-hidden {
  display: none!important;
}

#vwb-inbox-list {
  .overflow-visible {
    .table-list,
    .infinite-list {
      overflow: visible!important;
      z-index: 1;
    }
  }

  #head_box_vwbId, .cell_box_vwbId, #footer_box_vwbId {
    width: 10% !important;
    min-width: 10%;
  }

  #head_box_createdAt, .cell_box_createdAt, #footer_box_createdAt {
    width: 12% !important;
    min-width: 12%;
  }

  #head_box_action, .cell_box_action, #footer_box_action {
    width: 8%!important;
    min-width: 8%;
  }

  #head_box_vwbStatus, .cell_box_vwbStatus, #footer_box_vwbStatus {
    width: 18!important;
    min-width: 18%;
    text-overflow: ellipsis;
    white-space: nowrap !important;
  }

  #head_box_vwbCategory, #footer_box_vwbCategory {
    width: 30%!important;
    min-width: 30%;
  }

  .cell_box_vwbCategory {
    width: 30%!important;
    min-width: 30%;
    text-overflow: ellipsis;
    white-space: nowrap !important;
    overflow: hidden;
  }
}

#fzv-inbox-list {
  .overflow-visible {
    .table-list,
    .infinite-list {
      overflow: visible!important;
      z-index: 1;
    }
  }

  .table-list .custom-table .custom-table-cell {
    padding: 0.8rem .75rem;
  }

  #head_box_fzvProcess,
  .cell_box_fzvProcess,
  #footer_box_fzvProcess {
    width: 5%!important;
    min-width: 5%!important;
  }

  #head_box_fzvStatus, .cell_box_fzvStatus, #footer_box_fzvStatus {
    width: 14%!important;
    min-width: 14%;
  }

  #head_box_fzvCategory, #footer_box_fzvCategory {
    width: 30%!important;
    min-width: 30%;
  }

  .cell_box_fzvCategory {
    width: 30%!important;
    min-width: 30%;
    text-overflow: ellipsis;
    white-space: nowrap !important;
    overflow: hidden;
  }

  #head_box_fzvId, .cell_box_fzvId, #footer_box_fzvId {
    width: 10%!important;
    min-width: 10%;
  }

  #head_box_createdAt, .cell_box_createdAt, #footer_box_createdAt {
    width: 12% !important;
    min-width: 12%;
  }

  #head_box_action, .cell_box_action, #footer_box_action {
    width: 5% !important;
    min-width: 5%;
  }

  #head_box_fzvType, .cell_box_fzvType, #footer_box_fzvType {
    width: 7.5% !important;
    min-width: 7.5%;
  }
}

.capitalize {
  text-transform: capitalize;
}

#role-list {
  #head_box_users, .cell_box_users, #head_box_groups, .cell_box_groups {
    width: 15% !important;
    min-width: 15%;
  }

  #head_box_roleName, .cell_box_roleName {
    width: 20% !important;
    min-width: 20%;
  }

  #head_box_action, .cell_box_action {
    width: 20% !important;
    min-width: 20%;
  }

  .cell_box_roleName {
    text-transform: capitalize;
  }

  #head_box_permissionSummary, .cell_box_permissionSummary {
    width: 30% !important;
    min-width: 30%;
  }

  .cell_box_permissionSummary {
    text-overflow: ellipsis;
    white-space: nowrap !important;
    overflow: hidden;
  }
}

#role-group-list {
  .table-list {
    padding: 10px 0 10px 0;
  }

  #head_box_roles, .cell_box_roles {
    width: 35% !important;
  }

  .cell_box_action {
    min-width: 45%;
  }
}

#role-user-list {
  .table-list {
    padding: 10px 0 10px 0;
  }

  #head_box_email, .cell_box_email {
    width: 35% !important;
  }

  .cell_box_action {
    min-width: 45% !important;
  }
}

#user-list-management, #invited-user-list-management, #organization-list-manager {
  &.overflow-visible {
    .table-list,
    .infinite-list {
      overflow: visible!important;
    }
  }

  .table-list {
    padding: 25px 0px;
    .custom-table-heading {
      border-top: none;
    }
  }

  .cell_box_email, .cell_box_roles {
    text-overflow: ellipsis;
    white-space: nowrap !important;
    overflow: hidden;
  }
}

#user-list-management, #invited-user-list-management {
  .table-list {
    .custom-table {
      .cell_box_selection, #head_box_selection, #footer_box_selection {
          width: 4% !important;
          min-width: 4% !important;
      }
    }
  }
}

#organization-list-manager {
  .cell_box_action, #head_box_action, #footer_box_action {
    padding-left: 20px;
    width: 4% !important;
    min-width: 4%;
  }

  .cell_box_parent {
    text-overflow: ellipsis;
    white-space: nowrap !important;
    overflow: hidden;
  }
  #head_box_ern, .cell_box_ern{
    white-space: nowrap !important;
    display: inline-flex;
  }

  .break-word {
    word-wrap: break-word;
  }

  .text-ellipsis {
    width: 92%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

#step-tabpane-userList {
  #user-list-management {
    .table-list {
      padding: 0px;
    }
  }
}

#orgList, #userList, #invitedUserList {
  .search-box {
    max-width: 364px;
  }
  #add-organization-button {
    max-width: 268px;
  }
  #add-users-button {
    max-width: 252px;
  }
}

#policy-vwb-list {
  .overflow-visible {
    .table-list,
    .infinite-list {
      overflow: visible!important;
      z-index: 1;
    }
  }

  .table-list .custom-table .custom-table-cell {
    padding: 0.8rem .75rem;
  }

  .table-wrap .custom-table {
    width: 100%;
  }

  #head_box_vwbId, .cell_box_vwbId, #footer_box_vwbId {
    width: 12% !important;
    min-width: 12%;
  }

  #head_box_vwbStatus, .cell_box_vwbStatus, #footer_box_vwbStatus {
    width: 28% !important;
    min-width: 28%;
  }

  #head_box_vwbCategory, #footer_box_vwbCategory {
    width: 30% !important;
    min-width: 30%;
  }

  .cell_box_vwbCategory {
    width: 30% !important;
    min-width: 30%;
    text-overflow: ellipsis;
    white-space: nowrap !important;
    overflow: hidden;
  }

  #head_box_action, .cell_box_action, #footer_box_action {
    width: 8% !important;
    min-width: 8%;
  }
}

#policy-fzv-list {
  .overflow-visible {
    .table-list,
    .infinite-list {
      overflow: visible!important;
      z-index: 1;
    }
  }

  .table-list .custom-table .custom-table-cell {
    padding: 0.8rem .75rem;
  }

  #head_box_fzvId, .cell_box_fzvId, #footer_box_fzvId {
    width: 14%!important;
    min-width: 14%;
  }

  #head_box_fzvProcess,
  .cell_box_fzvProcess,
  #footer_box_fzvProcess {
    width: 5%!important;
    min-width: 5%!important;
  }

  #head_box_createdAt,
  .cell_box_createdAt,
  #footer_box_createdAt {
    width: 12.5%!important;
    min-width: 12.5%!important;
  }

  #head_box_fzvStatus, .cell_box_fzvStatus, #footer_box_fzvStatus {
    width: 20%!important;
    min-width: 20%;
  }

  #head_box_fzvCategory, #footer_box_fzvCategory {
    width: 30% !important;
    min-width: 30%;
  }

  .cell_box_fzvCategory {
    width: 30% !important;
    min-width: 30%;
    text-overflow: ellipsis;
    white-space: nowrap !important;
    overflow: hidden;
  }

  #head_box_fzvType, .cell_box_fzvType, #footer_box_fzvType {
    width: 10% !important;
    min-width: 10%;
  }
}

.full-height {
  height: 100vh;
  overflow: scroll;
}

.empty-footer.card-footer {
  height: 94px;
  border-top: none;
}

#email-templates-list {
  .table-list {
    padding: 10px 0;

    #head_box_body_html, .cell_box_body_html, #footer_box_body_html {
      width: 27%!important;
    }

    #head_box_name, .cell_box_name, #footer_box_name {
      width: 25%!important;
      white-space: pre-line;
    }
  }
}

#document-templates-list {
  .table-list {
    padding: 10px 0;

    #head_box_name, .cell_box_name, #footer_box_name,
    #head_box_timePublished, .cell_box_timePublished, #footer_box_timePublished {
      width: 15% !important;
    }

    #head_box_body_html, .cell_box_body_html, #footer_box_body_html,
    #head_box_timeDraft, .cell_box_timeDraft, #footer_box_timeDraft {
      width: 25% !important;
    }

    .template-list-thumbnail-container {
      background: white;
    }
  }
}

#scenario-analysis-list {
  #head_box_status, .cell_box_status,
  #head_box_updatedAt, .cell_box_updatedAt {
    min-width: 15%;
  }

  #head_box_name, .cell_box_name {
    min-width: 20%;
    width: 20% !important;
  }

  #head_box_userName, .cell_box_userName {
    min-width: 25%;
  }

  .cell_box_name a {
    text-overflow: ellipsis;
    white-space: nowrap !important;
    overflow: hidden;
  }
}

#create-scenario-analysis,
#scenario-analysis-details {
  .modal-full-container {
    width: 96%;
  }
}

#workflow-instance-view-page {
  .wf-info-block {
    margin-bottom: 40px;
    ul {
      padding-left: 20px;
      li {
        padding-left: 5px;
      }
    }
  }
}

#header-dropdown-settings {
  .dropdown-menu {
    padding: 10px 0;
    li {
      a {
        color: $theme-heading-color;
        font-size: 14px;
        padding: 10px 20px;

        i {
          margin-right: 10px;
        }
      }

      &.active,
      &:active,
      &:focus,
      &:hover {
        background: transparentize($primary-color, 0.9);
        > a {
          background: transparent;
        }
      }
    }
  }
}

.ticket-list-menu {
  .dropdown {
    .actions-menu {
      padding: 0;
      line-height: 1.4;

      .pro-body {
        padding: 10px 0;
        margin-bottom: 0;
        list-style: none;

        li {
          a {
            color: $theme-heading-color;
            font-size: 14px;
            padding: 10px 20px;

            i {
              margin-right: 10px;
            }
          }

          &.active,
          &:active,
          &:focus,
          &:hover {
            background: transparentize($primary-color, 0.9);
            > a {
              background: transparent;
            }
          }
        }
      }
    }
  }

  &.drp-tickets-actions.show {
    &:before {
      color: $primary-color;
    }
  }
}

#new-ticket-page,
#ticket-details-page {
  .card-body {
    padding: 20px 25px;
  }
}

.textarea-wrap {
  white-space: pre-wrap;
}

.filter-type {
  position: absolute;
  display: block;
  left: 25px;
  top: 10px;
  font-size: 13px;
  z-index: 9;
}

.export-button {
  padding: 10px 20px;
}

.download-button {
  margin: 4px 10px 5px 4px;
}

#fee-card, #log-book {
  .card-header {
    cursor: pointer;
  }

  .card-body {
    min-height: auto;
  }
}

.time-filter-button {
  button, .btn-primary {
    background: transparent;
    width: 100%;
    text-align: left;
    color: $theme-font-color;
    border: 1px solid hsl(0,0%,80%);
    padding: 7.5px 65px 7.5px 10px;

    &:hover, &:not(:disabled):not(.disabled):active, &:focus {
      background: transparent;
      color: $theme-font-color;
      border: 1px solid hsl(0,0%,70%);
    }

    &:not(:disabled):not(.disabled):active:focus {
      border-color: $theme-blue-color;;
      box-shadow: 0 0 0 1px $theme-blue-color;;
    }

    &:disabled {
      background-color: hsl(0,0%,95%);
      border-color: hsl(0,0%,90%);
      color: $theme-font-color;
      opacity: 1;
    }
  }

  .react-datepicker {
    button {
      border: transparent;
    }

    button:not(:disabled):not(.disabled):active {
      border: 0.45rem solid transparent;
      background: none;
    }

    .react-datepicker__navigation {
      background: none;
      line-height: 1.7rem;
      text-align: center;
      cursor: pointer;
      position: absolute;
      top: 10px;
      width: 0;
      padding: 0;
      border: 0.45rem solid transparent;
      z-index: 1;
      height: 10px;
      width: 10px;
      text-indent: -999em;
      overflow: hidden;
    }

    .react-datepicker__navigation--previous {
      border-right-color: $loki-color-neutral;
    }

    .react-datepicker__navigation--next {
      right: 95px;
      border-left-color: $loki-color-neutral;
    }
  }

  .datepicker-to, .datepicker-from {
    width: 225px;
  }

  button.dropdown-toggle {
    &:before {
      position: absolute;
      right: 35px;
      top: 2.5px;
      content: "|";
      font-weight: 300;
      font-size: 20px;
      color: hsl(0,0%,80%);
    }

    &:after {
      position: absolute;
      right: 15px;
      top: 4px;
      content: "\f107";
      font-family: 'FontAwesome';
      font-weight: 600;
      border: 0;
      font-size: 20px;
      vertical-align: middle;
      color: hsl(0,0%,80%);
    }
  }

  .dropdown-menu {
    width: 520px;
    margin-top: 10px;
    padding-top: 10px;
  }

  .dropdown-menu.show:before {
    display: none;
  }

  .nav-tabs {
    .nav-link {
      color: $tab-nav-color;
    }

    .nav-link.active {
      text-decoration: underline;
    }
  }

  .tab-content, .nav-tabs .nav-link.active {
    background: transparent;
    box-shadow: none;
  }

  .tab-content {
    padding: 10px 25px 0px 40px;
  }

  .reset-time-filter {
    background: $secondary-color;
    width: 120px;
    text-align: center;
    color: white;
    border: 1px solid $secondary-color;
    padding: 10px 20px;

    &:hover, &:not(:disabled):not(.disabled):active, &:focus {
      background: $secondary-color-hover;
      border-color: $secondary-color-hover;
      color: white;
      border: 1px solid;
    }
  }

  .apply-time-filter {
    background: $primary-color;
    width: 120px;
    text-align: center;
    color: white;
    border: 1px solid $primary-color;
    padding: 10px 20px;

    &:hover, &:not(:disabled):not(.disabled):active, &:focus {
      background: $primary-color-hover;
      border-color: $primary-color-hover;
      color: white;
      border: 1px solid;
    }
  }

  .radio input[type=radio] + .form-check-label:after, .radio input[type=radio] + .cr:after {
    top: 16px;
  }
}

.time-filter-button.filtered {
  .btn-primary.dropdown-toggle {
    color: $theme-heading-color
  }
}

.time-filter-button.show {
  .btn-primary.dropdown-toggle {
    background-color: transparent;
    color: $theme-font-color;
    border-color: $theme-blue-color;
    box-shadow: 0 0 0 1px $theme-blue-color;

    &:focus {
      box-shadow: 0 0 0 1px $theme-blue-color;
    }
  }

  button.dropdown-toggle {
    &:after {
      color: $theme-font-color;
    }
  }
}

.btn-primary.disabled, .btn-primary:disabled {
  background: $primary-color;
  border-color: $primary-color;
}

.disabled-color {
  color: $color-disabled;
}

.align-middle-icon {
  position: relative;
  top: 2px;
}

.btn-danger {
  background-color: $danger-color;
  border-color: $danger-color;
}

input[type='number'] {
  appearance: textfield;
  -moz-appearance: textfield; /*For FireFox*/
  &::-webkit-inner-spin-button { /*For Webkits like Chrome and Safari*/
    -webkit-appearance: none;
    margin: 0;
  }
}

@media screen and (max-width: 1440px) {
  #account-card {
    .col-md-6 {
      flex: 0 0 100%;
      max-width: 100%;

      .app-field-plaintext {
        padding: 0;
      }
    }
  }
}

@media screen and (max-width: 1199px) {
  .sm-pl-15 {
    padding-left: 15px;
  }

  .sm-mt-10 {
    margin-top: 10px;
  }

  #policy-debit-credit-list {
    .table-wrap .custom-table {
      width: 550px !important;
    }
  }
}

.emil-styled {
  &.dropdown-menu {
    .dropdown-header {
      padding-top: 1rem;
      color: $theme-heading-color;
    }
    .dropdown-item {
      font-size: 0.875rem;
      .dropdown-item-img {
        width: 16px;
        height: 16px;
        border-radius: 4px;
      }
      &.active {
        color: $primary-color;
        background-color: transparent;
      }
      &.disabled {
        color: $disabled;
      }
    }
  }
}

.app-vertical-container {
  min-height: inherit;
  display: flex;
  flex-direction: column;
  .pcoded-content {
    flex: 1 0 auto;
  }
  .app-footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex: 0 0 auto;
    .dropdown {
      .dropdown-toggle {
        line-height: 18px;
        display: flex;
        &:after {
            content: "keyboard_arrow_up";
            font-family: 'Material Icons';
            font-size: 18px;
            border: none;
            vertical-align: 0;
        }
      }

      .dropdown-menu {
        margin-bottom: 15px;
        margin-top: 0;
        padding: 10px 0;
        &.show {
          &:before {
            content: "\64";
            bottom: -5px;
            top: auto;
            text-shadow: 0 2px 2px rgba(69, 90, 100, 0.12);
          }
        }
      }
    }
  }
}

.app-secondary-link {
  color: $secondary-color;
  &:hover {
    color: $secondary-color-hover;
  }
}

// Email Editor
.color-input-icon {
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  position: absolute;
  margin-top: 12.5px;
  margin-left: 15px;
  border-radius: 50%;
  border: 1px solid $user-search-border-color;
}

.no-color-icon {
  &:after {
    position: absolute;
    content: '';
    left: 15px;
    top: 2px;
    border-top: 1px solid $danger-color;
    width: 18px;
    transform: rotate(135deg);
    transform-origin: 0% 0%;
  }
}

.email-header, .email-footer {
  p {
    margin-bottom: 0;
  }
}

.fullscreen-bg {
  background: white;
}

.fs-icon {
  cursor: pointer;
}

.list-thumbnail-container {
  border: 1px solid $disabled;
  box-shadow: 0px 1px 20px rgba(69, 90, 100, 0.08);
  border-radius: 4px;
  display: inline-block;
  position: relative;

  .list-thumbnail {
    zoom: normal;
    transform: scale(0.254);
    -ms-zoom: 0.254;
    -moz-transform: scale(0.254);
    transform-origin: 0 0;
    -moz-transform-origin: 0 0;
    -o-transform: scale(0.254);
    -o-transform-origin: 0 0;
    -webkit-transform: scale(0.254);
    -webkit-transform-origin: 0 0;
    width: 228px;
    height: 228px;

    iframe {
      width: 900px;
      height: 900px;
      border: 2px solid $list-thumbnail-border;
      border-radius: 4px;
    }
  }
}

.template-list-thumbnail-container {
  position: relative;
  width: 130px;
  height: 155px;
  border: 1px solid $list-thumbnail-border;
  border-radius: 4px;
  overflow: hidden;
  white-space: pre-line;

  .template-list-thumbnail {
    zoom: normal;
    transform: scale(0.142);
    -ms-zoom: 0.142;
    -moz-transform: scale(0.142);
    transform-origin: 0 0;
    -moz-transform-origin: 0 0;
    -o-transform: scale(0.142);
    -o-transform-origin: 0 0;
    -webkit-transform: scale(0.142);
    -webkit-transform-origin: 0 0;

    iframe {
      width: 900px;
      height: 990px;
      border-radius: 25px;
    }
  }

  .draft-thumbnail {
    background: white;
    max-width: 130px;

    p {
      text-align: center;
      padding: 25px;
      font-size: 14px;
      color: $list-thumbnail-label-color;
    }
  }

  .list-thumbnail-label {
    position: absolute;
    bottom: 0;
    height: 30px;
    background: $theme-background;
    width: 100%;
    text-align: center;
    border-top: 1px solid $list-thumbnail-border;
    color: $list-thumbnail-label-color;
  }
}

.edit-design-button {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.design-actions-dropdown {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: -1px;
}

.design-actions-dropdown-single {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.template-designs-list {
  .template-list-thumbnail-container {
    width: 145px;
    height: 155px;
    cursor: pointer;

    &:hover {
      border: 1px solid $tab-nav-color;
      box-shadow: 0 0 0 1px $tab-nav-color;
    }

    .template-list-thumbnail {
      zoom: normal;
      transform: scale(0.159);
      -ms-zoom: 0.159;
      -moz-transform: scale(0.159);
      transform-origin: 0 0;
      -moz-transform-origin: 0 0;
      -o-transform: scale(0.159);
      -o-transform-origin: 0 0;
      -webkit-transform: scale(0.159);
      -webkit-transform-origin: 0 0;
    }

    iframe {
      height: 900px;
    }
  }
}

.show-cursor {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  cursor: pointer;
  z-index: 100;
}

.design-selected {
  border: 1px solid $tab-nav-color;
  box-shadow: 0 0 0 1px $tab-nav-color;
}

.email-name {
  width: 228px;
  white-space: pre-line;
}

input[type="checkbox"].form-control-checkbox-medium {
  // those 3 lines below are needed for events like space key, onBlur, onClick to work...
  width: 18px;
  height: 18px;
}

// User list component
#user-list {
  .table-list .custom-table .custom-table-heading {
    border-bottom: 1px solid $theme-border;
    border-top: 1px solid $theme-border;
  }

  .table-list .custom-table .table-footer .custom-table-heading {
    border-bottom: 0;
  }
  .table-list .custom-table .custom-table-cell,
  .table-list .custom-table .custom-table-head {
    float: none;
    padding: 1.05rem .75rem;
    width: auto;
    min-width: 20%;
    display: inline-block;
    border: 0;
  }

  #head_box_action,
  .custom-table-cell.cell_box_action {
    white-space: nowrap!important;
  }
}

// Document Preview
.document-preview iframe, .email-preview iframe {
  width: 100%;
}

#policy-document-list {
  .cell_box_entityType, .cell_box_description {
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap !important;
    overflow: hidden;
  }
  .cell_box_filename {
    a {
      width: 100%;
      position: relative;
      span {
        width: 92%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}

#workflow-instance-list {
  .table-list {
    padding: 0;
    #head_box_selection, .cell_box_selection {
      width: 4% !important;
      min-width: 4% !important;
    }
  }
}

#policy-invoice-list {
  #head_box_createdAt, #footer_box_createdAt, .cell_box_createdAt,
  #head_box_dueDate, #footer_box_dueDate, .cell_box_dueDate,
  #head_box_grossAmount, #footer_box_grossAmount, .cell_box_grossAmount {
    min-width: 13%;
    width: 13% !important;
  }

  #head_box_grossAmount, #footer_box_grossAmount, .cell_box_grossAmount {
    text-align: right;
    padding-right: 30px;
  }
}

#policy-debit-credit-list {
  #head_box_createdAt, #footer_box_createdAt, .cell_box_createdAt {
    min-width: 20%;
    width: 20% !important;
  }

  #head_box_debit, #footer_box_debit, .cell_box_debit,
  #head_box_credit, #footer_box_credit, .cell_box_credit,
  #head_box_balance, #footer_box_balance, .cell_box_balance  {
    min-width: 15%;
    width: 15% !important;
    text-align: right;
    padding-right: 30px;
  }

  #head_box_description, .cell_box_description, #footer_box_description {
    width: 35% !important;
  }

  .table-wrap .custom-table {
    width: 100%;
  }
}

.table-tooltip {
  .tooltip-inner {
    background: white;
    color: $theme-heading-color;
    font-weight: 500;
    border-radius: 10px;
    padding: 10px;
    max-width: 100%;
    float: right;
  }

  .arrow::before {
    display: none;
  }
}

// Loading full container
.modal-full-container {
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 99999;
  margin: 0 auto;
  align-items: center;
  .loading-spinner {
    height: 100%;
    align-items: center;
  }
}

.modal-full-container.show {
  opacity: 0.5;
  background-color: $color-white;
}

.m-t-45p {
  margin-top: 45%;
}

.m-b-40 {
  margin-bottom: 40px;
}

.m-b-30 {
  margin-bottom: 30px;
}

.m-b-15 {
  margin-bottom: 15px;
}

.table-border-top {
  border-top: 2px solid $theme-border;
}

.table-border-bottom {
  border-bottom: 2px solid $theme-border;
}

.align-vertical-bottom {
  vertical-align: bottom !important;
}

.l-h-28 {
  line-height: 28px;
}

.active-tab {
  .estimation-dates {
    font-weight: 600;

    i.chevron {
      display: none;
    }
  }

  .estimation-price {
    display: none;
  }
}

.invoice-intervals {
  tr.collapse-tab {
    td {
      border-top: none;
    }

    &:hover {
      background: none;
    }
  }

  .estimation-tab {
    cursor: pointer;
  }
}

.estimation-invoice-tab {
  border-top: 1px solid $search-border-color;
  margin-top: -1px;
}

.line-h-20 {
  line-height: 20px;
}

.new-estimation-summary {
  tr th {
    border: none;
    padding-bottom: 0;
  }
}

.seperator-line {
  border-left: 1px solid $disabled;
}

.estimation-invoices-section {
  margin-left: -25px;
  margin-right: -25px;
}

.estimation-invoices-accordian {
  width: 100%;
}

.error-text {
  color: $errorText;
  font-size: 80%;
}

.search-border-top {
  border-top: 1px solid $search-border-color;
}

.wf-ml-10 {
  margin-left: 10px;
}

.wf-ml-40 {
  margin-left: 40px;
}

.instance-event {
  border: 1px solid $color-lite-gray;
  border-radius: 5px;

  i.feather.icon-x-square {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    display: inline-block;
  }

  i.feather.bold {
    font-weight: bold;
  }
}

.instance-event-status {
  width: 35px;
}

#plugins-panel {
  .modal-full-container {
    width: 97%;
    z-index: 999;
  }
}
.img-plugin {
  width: inherit;
  max-height: 52px;
}

.notifications-demo {
  .receiver {
    border: 1px solid black;
    margin-top: 12px;
    .payload {
      border-top: 1px solid black;
      color: green;
      font-weight: bolder;
    }
    &.system {
      border-color: green;
      &.error {
        border-color: red;
        .payload {
          color: red;
        }
      }
    }
    > * {
      padding: 12px;
    }
  }
}

//thumbnails blocks background
.thumbnail-block-1 {
  background: $thumbnail-block-1;
}

.thumbnail-block-2 {
  background: $thumbnail-block-2;
}

.theme-secondary-bg {
  background: $secondary-color;
}

.theme-fail-bg {
  background: $fail-color;
}

.success-text {
  color: $success-color;

  &:hover {
    text-decoration: underline;
    color: $success-color;
  }
}

//tags input css
.tags-input {
  div {
    background: $theme-background;
    border: 1px solid $user-search-border-color;

    span {
      overflow: hidden;
      background: $info-color;
      color: white;
      font-size: 14px;
      padding: 5px;

      button {
        color: white;
      }
    }

    input {
      width: 100%;
      background: $theme-background;
      height: 100px;
      padding: 0 0 75px;
    }
  }
}

.gradient-color-1 {
  background: $theme-color;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color:transparent;
}

.btn-success-screen {
  background: $successButton;
}

a.btn-success-screen {
  color: white;
  font-weight: 400;
  &:hover {
    color: white;
  }
}

button.condtion-button {
  background: $condtion-background;
  width: 100%;
  border: 1px solid $disabled;

  &:hover, &:not(:disabled):not(.disabled):active, &:focus {
    background: $condtion-background;
    border-color: $tab-nav-color;
    color: $color-c-black;
  }
}

button.condtion-button.isActive {
  border: 2px solid $tab-nav-color;
}

.select-item__control--is-disabled {
  background: $button-hover!important;
  opacity: 1;
  border: 1px solid $list-thumbnail-border!important
}

.hide-tab {
  color: $list-thumbnail-border;
  display: none;
}

#policy-view-page {
  .custom-chevron-down {
    right: 26px;
    position: absolute;
    top: 13px;
  }

  .updating-policy {
    #policy-form {
      &.show-hide-tabs {
        .hide-tab {
          display: block;
          text-align: justify;
        }
      }

      .hide-tab.active {
        display: block;
      }

      #policy-updating-buttons{
        position: absolute;
        top: 15px;
        width: 93%;
      }

      .btn-policy-edit {
        min-width: 115px;
        padding: 9px 10px;
        display: inline-block;
        margin: 0 10px 0 15px;
      }

      .nav-pills {
        padding-top: 70px;
      }
    }
  }
  .summary-tab {
    display: block;
    text-transform: none;
    color: $theme-font-color;
  }

  .nav-link.active {
    .summary-tab {
      color: $color-white;
    }
  }
}

#md-confirm-update-policy {
  .modal-full-container {
    width: 94%;
    margin: -15px auto 0 auto;
  }

  .md-saved-date-info {
    max-height: 350px;
    overflow-y: auto;
    padding: 0;
    margin: 0;
    overflow-x: hidden;
  }
}

#md-confirm-update-lead-status {
  .modal-full-container {
    width: 94%;
    margin: -15px auto 0 auto;
  }
}

.lead-status-dropdown {
  .dropdown-toggle {
    border-radius: 50px;
    padding: 5px 16px 7px 16px;
  }
}

.lead-status-dropdown-without-elements {
  .btn-primary {
    &:hover {
      cursor: default;
      background: $primary-color;
      border-color: $primary-color;
    }
  }
  .dropdown-toggle {
    border-radius: 50px;
    padding: 5px 16px 7px 16px;

    &:after {
      display: none;
    }
  }

}

.lead-status-dropdown-declined {
  .dropdown {
    margin-right: 0px;
  }
  .dropdown-toggle {
    border-radius: 50px;
    padding: 5px 16px 7px 16px;
    background-color: $secondary-color;
    pointer-events: none;
    border: none;

    &:after {
      display: none;
    }
  }
}

.lead-status-dropdown-approved {
  .dropdown {
    margin: 0;
  }

  .dropdown-toggle {
    border-radius: 50px;
    padding: 5px 16px 7px 16px;
    background: $color-approved;
    pointer-events: none;
    border: none;

    &:after {
      display: none;
    }
  }
}

.lead-dropdown {
  display: flex;
  width: 468px;
  flex-direction: column;
  align-items: flex-start;
  font-size: 14px;
  font-family: Open Sans;
  line-height: 21px;

  p {
    color: var(--primary-gray, #888);
  }

  .form-check {
    padding-top: 10px !important;
  }

  .form-check-label {
    padding: 0px 0px 10px 5px !important;
  }

  .dropdown-toggle {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 11px 16px;
    background: var(--gray-05, #F4F7FA) !important;
    color: var(--gray-02, #495057) !important;
    border-radius: 4px;
    border: 1px solid var(--gray-04, #CED4DA);
    width: 468px;
    text-align: left;
  }

  .dropdown-toggle::after {
    display: none !important;
  }

  .dropdown-menu {
    max-height: 250px;
    overflow-y: auto;
    width: 468px;
  }

  i {
    width: 20px;
    height: 20px;
    color: #000000;
    align-items: left;
    margin: 0px;
  }

  .form-check-label {
    padding-top: 2px;
    padding-left: 5px;
    color: #111111;
  }

  .form-check-input {
    width: 15px;
    height: 15px;
  }
}

.md-lead-popup {
  .modal-body {
    padding: 0;
  }

  p {
    margin-bottom: 8px;
  }

  .col {
    display: flex;
    padding: 16px 16px 32px 16px;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

   .dropdown-toggle {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 11px 16px;
   }

   .dropdown-item {
    font-size: 14px;
    padding: 4px 20px;
   }

   .dropdown-toggle::after {
    display: none !important;
  }
}

#status-dropdown-header {
  padding-top: 8px;
}

.info-icon {
  width: 24px;
  height: 24px;
  color: #04A9F5;
  margin: 0px;
}

#btn-add-object {
  margin-top: 24px;
}

.c-blue {
  color: $blue;
}

.claim-container {
  .cell_box_deleteClaimButton {
    text-align: right;
    padding: 0 25px 0;
  }
}

.banner-alert {
  padding-bottom: 10px;
}

#payment-page{
  .MuiCard-root {
    box-shadow: none;
    .MuiCardHeader-root,
    .MuiCardContent-root {
      padding-left: 0;
      padding-right: 0;
      .MuiGrid-item {
        margin-bottom: 10px;
      }
      .MuiGrid-grid-xs-true {
        flex-basis: auto;
      }
    }
  }
}

#org-page {
  .tab-content {
    padding: 0;
  }
  p {
    color: $color-c-black;
  }

  .btn-link {
    text-decoration: underline;
    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
}
.d-none-nav {
  display: none!important;
}

.search-box {
  min-width: 94px;
  input {
    padding-right: 50px;
    border-radius: 0.25rem!important;
  }
  .input-group-append {
    margin-left: -50px;
    z-index: 999;
    border: none;
    span {
      border: none;
    }
  }
}

#docx-editor-page, #static-editor-list {
  #docx-editor-list, #docx-template-details {
    .card .card-body {
      padding-top: 20px;
      hr {
        margin-top: 18px;
        margin-bottom: 25px;
      }
    }
  }

  .custom-table .custom-table-heading {
    border-top: none;
  }

  .dropdown-toggle::after {
    font-family: 'Material Icons';
    content: "more_horiz";
    border: none;
    vertical-align: middle;
    margin: 0;
    font-size: 18px;
  }
  .table-list {
    padding: 10px 0px;
  }
  .docx-preview {
    iframe {
      width: 100%;
    }
  }

  .template-list-thumbnail-container{
    background-color: #fff;
    .loading-spinner{
      margin-bottom: -90px;
      z-index: 999;
      position: relative;
      margin-top: 60px;
    }
  }

  .active-icon, .actions-icon {
    width: 18px;
    height: 18px;
    fill: $brand-background;
    margin-right: 0.7rem;
    vertical-align: text-top;
    position: relative;
    top: 2px;
  }

  .active-icon {
    fill: #007BFF;
  }
}

.delete_docx i {
  color: $brand-background;
}

.docx-context-menu:hover, .docx-context-menu:focus {
  color: $brand-background;
}

.bf-widgets {
  padding: 40px 30px 60px 30px;
  background: $color-white;
}

.bf-policy-widgets {
  padding: 70px 30px 60px 30px;
  background: $color-white;
}

.emil-badge {
  border-radius: 15px;
  padding: 5px 16px 7px 16px;
  background: $primary-color;
  color: $color-white;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  white-space: nowrap;
}

.list-actions-dropdown {
  .dropdown-menu {
    padding: 0px;
    min-width: 200px;
    .dropdown-item {
      margin: 8px 0px;
      padding: 6px 24px;
    }
  }
  .show:before {
    left: auto;
    right: 7px;
  }
  .btn {
    margin: 0px;
    padding: 0px;
  }
}


#static-document-form {
  .form-group {
    margin-top: 5px;
  }
}

#static-document-details {
  #import-export {
    margin-right: 10px;
  }
}

#organization-page {
  .custom-table .custom-table-heading {
    border-top: none;
  }
  .alert, .nav-pills{
    margin-bottom: 20px!important;
  }
  .tab-content {
    padding: 26px 25px 30px;
  }
}

.col-width-44 {
  width: 44%;
  min-width: 44%;
}

.col-width-28 {
  width: 28%;
  min-width: 28%;
}

// TODO - update ckeditor4-react version
.cke_notifications_area {
  display: none;
}

#eis-home .eis-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

#eis-home .col-xl-4,
#eis-home .col-md-6 {
  display: flex;
  flex-direction: column;
}

#eis-home .card {
  flex: 1;
  display: flex;
  flex-direction: column;
  .break-word {
    word-wrap: break-word;
  }
}

.react-multi-email {
  &.default {
    &.focused {
      box-shadow: 0 0 0 0.2rem #007bff40;
    }
    border: 1px solid #CED4DA;
  }
  &.error {
    &.focused {
      box-shadow: 0 0 0 0.2rem #dc354540;
    }
    border: 1px solid #dc3545!important;
  }
  overflow: auto;
  height: 97px;
  min-height: 97px;
  background-color: #F4F7FA!important;
  border: 1px solid #cccccc;
  input {
    background-color: inherit;
  }
  .data-labels > div {
    &.error {
      background-color: #dc354540!important;
    }
    background: #EAEAEA;
    color: #495057;
    font-size: 11,9px;
    font-weight: 400;
    border-radius: 2px;
    padding: 5px 6px;
  }
}

.form-group .entity-info-box span {
  color: #888888;
}

.entity-info-box {
  p {
    color: #888888;
    font-weight: 600;
    font-size: 11.2px;
    line-height: 16.8px;
  }
  span {
    cursor: pointer;
  }
}

.entity-info-cell {
  width: 100%;
  overflow: revert;
  span {
    cursor: pointer;
  }
}

.user-info-tooltip {
  margin-top: 10px;
  .tooltip-inner {
    width: 294px;
    min-width: 294px;
    max-width: 294px!important;
    background: white;
    color: $theme-heading-color;
    font-weight: 400;
    border-radius: 12px;
    padding: 18px;
    float: right;
    text-align: left;
    .col {
      flex-grow: 0;
    }
    .avatar-box {
      padding-right: 12px;
      .avatar {
        width: 34px!important;
        height: 34px!important;
      }
    }
    .content-box {
      width: 212px!important;
      min-width: 212px!important;
      max-width: 212px!important;
      p {
        font-size: 12px;
        color: #3F4D67;
      }
    }
  }

  .arrow::before {
    display: none;
  }
}
