.amount-not-paid {
  color: $danger-color;
}

.amount-paid {
  color: $success-color;
}

#finance-invoices-list {
  &.overflow-hidden{
    padding-bottom: 20px;
    .show:before {
      left: auto;
      right: 50px!important;
    }
  }

  .dropdown-toggle::after {
    font-family: 'Material Icons';
    content: "more_horiz";
    border: none;
    vertical-align: middle;
    margin: 0;
    font-size: 18px;
  }

  .active-icon, .actions-icon {
    width: 18px;
    height: 18px;
    margin-right: 0.7rem !important;
    vertical-align: text-top;
    position: relative;
    top: 2px;
    color: $brand-background;
  }

  .invoice-list-actions-dropdown {
    .btn {
      margin-right: 15px!important;
      padding: 2px;
      z-index: 1;
      position: relative;
    }
  }

  .invoice-number-text {
    align-items: center !important;
    display: inline-flex !important;
  }

  #head_box_grossAmount, #footer_box_grossAmount, .cell_box_grossAmount,
  #head_box_openAmount, #footer_box_openAmount, .cell_box_openAmount {
    text-align: right;
    padding-right: 30px;
  }

  #footer_box_status, #footer_box_openAmount {
    position: static!important;
  }

  #invoices-list {
    &.overflow-visible {
      .table-list,
      .infinite-list {
        overflow: visible!important;
      }
    }
  }
}

.invoice-document-preview-modal {
  width: 98% !important;
  max-width: 98%;
}

.invoice-document-preview-icon {
  background-color: #D0E7FF;
  border-radius: 5px;
  margin-right: 10px;
  color: #007bff;
  padding: 3px;
}

.show-payment-histoy-icon {
  border-radius: 5px;
  margin-right: 10px;
  color: #007bff;
}

.payment-history-invoice-data {
  color: $theme-heading-color;
}

.payment-details-card:last-child > hr {
  display: none;
}

#add-payment-form {
  .form-control:valid,
  .form-control.is-valid {
    border-color: $input-border-color;
    padding-right: 10px;
  }

  .form-control:valid:focus {
    border-color: $input-focus-border-color;
    box-shadow: $input-focus-box-shadow;
  }

  .form-control.is-invalid {
    border-color: $form-feedback-invalid-color;
  }

  .form-control.is-invalid:focus {
    box-shadow: 0 0 0 $input-focus-width rgba($form-feedback-invalid-color, 0.25);
  }

  input[type="date"].form-control.is-invalid::-webkit-calendar-picker-indicator {
    background-image: none;
  }

  .custom-select, .form-control {
    padding-right: 7px !important;
  }

  input[type="date"].is-invalid {
    background-image: url("../../images/calendar_picker_error.svg");
    background-repeat: no-repeat;
    padding-right: 4px;
  }

  .app-field-control {
    margin-bottom: 20px;

    .invalid-feedback {
      padding-top: 0.25em;
    }

    input[type="date"]::-webkit-calendar-picker-indicator {
      background: url("../../images/calendar_picker.svg") no-repeat;
    }
  }
}

#payment-history-modal {
  .payment-history-modal-header {
    display: flex;
  }

  .modal-header {
    justify-content: center;
    align-items: center;
  }

  .history-icon {
    margin-right: 10px;
  }
}

#payment-warning-modal {
  .btn-toolbar {
    .btn {
      margin-bottom: 0px;
    }
    .btn:last-child {
      margin-right: 0px;
    }
  }
}
