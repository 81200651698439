.help-center {
  .contact_support{
    .card-subtitle {
      color: $color-subtitle!important;
    }
    .card-header {
      border: none!important;
    }
  }
  .side-bar {
    .badge {
      font-weight: normal!important;
    }
    .badge-pill {
      border-radius: 4px!important;
    }
  }
}