/* Styles for edit workflow-instance-monitor page */
#workflow-view-page, #static-document-details {
  .pcoded-header {
    margin: 0;
    width: auto!important;
    min-height: 21px;
    z-index: 1027;
    align-items: center;
    background: none!important;
    .dropdown {
      height: 21px;
      margin-right: 20px;
      align-items: center;
      display: flex;
      .dropdown-toggle {
        line-height: 21px;
        font-weight: bold;
        &:after {
          right: -20px!important;
          line-height: 21px!important;
        }
      }
      .dropdown-menu  {
        margin-top: 10px;
        overflow: auto;
        max-height: 325px;
        .dropdown-item {
          padding: 10px 25px;
        }
      }
    }
    .dropdown.show:before {
      top: 28px;
    }
    .drp-text:before {
      left: 50px;
    }
    .import-export-btn {
      font-weight: normal;
      vertical-align: middle;
      &:before {
        font-family: "Material Icons";
        margin-right: -8px!important;
        font-size: 24px;
        border: none;
        position: inherit;
        vertical-align: middle;
        content: "import_export";
      }
    }
  }

  .card {
    .alert {
      padding: 10px 20px;
      margin-top: 1.2rem;
      margin-bottom: 0rem;
    }
  }

  .va-super {
    vertical-align: super;
  }

  .bpmn-container {
    min-height: 448px;
    background-color: #F5F7FA;
  }

  .loading-spinner {
    position: relative;
    margin-top: 20px;
  }

  #edit-switch-workflow-form {
    i {
      font-family: "Material Icons Outlined";
    }
  }
}

#new-workflow-page {
  .custom-file-label {
    overflow: hidden;
  }
  .radio .invalid-feedback {
    display: none;
  }
}

#workflow-list-page {
  .tab-content {
    padding: 30px 25px;
  }
  .content {
    padding: 20px 25px 30px;
    #statistics-wgt {
      .card-body {
          padding-top: 10px;
          padding-bottom: 10px;
        }

      .text14 {
        font-size: 14px;
      }

      .text12 {
        font-size: 12px;
      }

      .theme-bg, .theme-bg2, .theme-bg3, .theme-bg4 {
        border: 1px solid;
        h4, p {
          color: #FFFFFF;
        }
        box-shadow: 0px 1px 20px 0px #455A6414;
      }

      .theme-bg-zero, .theme-bg2-zero, .theme-bg3-zero, .theme-bg4-zero {
        border: 1px solid;
        background: #FFFFFF;
        box-shadow: 0px 1px 20px 0px #455A6414;
      }

      .theme-bg {
        background: linear-gradient(82.66deg, #1DC1E0 21.45%, #1DE9B6 100%);
        border-image-source: linear-gradient(82.66deg, #1DC1E0 21.45%, #1DE9B6 100%);
        border-image-slice: 1;
      }

      .theme-bg-zero {
        h4, p {
          background: linear-gradient(82.66deg, #1DC1E0 21.45%, #1DE9B6 100%);
          -webkit-text-fill-color: transparent;
          -webkit-background-clip: text;
          background-clip: text;
        }
        border-image-source: linear-gradient(82.66deg, #1DC1E0 21.45%, #1DE9B6 100%);
        border-image-slice: 1;
      }

      .theme-bg2 {
        background: linear-gradient(82.66deg, #9780CE 21.45%, #8093CE 100%);
        border-image-source: linear-gradient(265.16deg, #899FD4 6.64%, #A389D4 94.87%);
        border-image-slice: 1;
      }

      .theme-bg2-zero {
        h4, p {
          background: linear-gradient(265.16deg, #899FD4 6.64%, #A389D4 94.87%);
          -webkit-text-fill-color: transparent;
          -webkit-background-clip: text;
          background-clip: text;
        }
        border-image-source: linear-gradient(265.16deg, #899FD4 6.64%, #A389D4 94.87%);
        border-image-slice: 1;
      }

      .theme-bg3 {
        background: #748892;
        border: 1px solid #748892;
      }

      .theme-bg3-zero {
        h4, p {
          color: #748892;
        }
        border: 1px solid #748892;
      }

      .theme-bg4 {
        background: #F44236;
        border: 1px solid #F44236;
      }

      .theme-bg4-zero {
        h4, p {
          color: #F44236;
        }
        border: 1px solid #F44236;
      }
    }
  }
}

#workflow-list-page, #workflow-view-page {
  .table-list {
    width: auto;
    overflow-y: auto;
    padding: 0px;
    .custom-table {
      .custom-table-heading {
        border-top: none;
      }
    }
  }
}


#worflow-list-items  {
  .table-list {
    overflow: auto;
    padding: 0px;
    .custom-table {
      width: max-content;
      .custom-table-heading {
        border-top: none;
      }
    }

    #head_box_workflowName, .cell_box_workflowName, #footer_box_workflowName {
      width: 500px!important;
    }

    #head_box_instanceId, .cell_box_instanceId, #footer_box_instanceId {
      width: 200px!important;
    }

    #head_box_createdAt, .cell_box_createdAt,
    #head_box_updatedAt, .cell_box_updatedAt {
      width: 200px!important;
    }

    #head_box_versionNumber, .cell_box_versionNumber {
      width: 100px!important;
    }

    #head_box_entityNumber, .cell_box_entityNumber, #footer_box_entityNumber {
      width: 200px!important;
    }

    #head_box_action, .cell_box_action, #footer_box_action {
      width: 100px!important;
    }
  }

  .custom-table .table-header,
  .custom-table .table-footer {
    bottom: 0px!important;
    z-index: 5;
    background-color: #ffffff;
  }

  .table-list .custom-table #head_box_selection,
  .table-list .custom-table .cell_box_selection,
  .table-list .custom-table #footer_box_selection {
    width: 42px !important;
    left: 0px;
    z-index: 5;
  }

  #head_box_selection, #footer_box_selection {
    background-color: #ffffff;
    color: #ffffff;
  }

  .table-row-even {
    .cell_box_selection {
      background-color: #f4faff;
    }
  }

  .table-row-odd {
    .cell_box_selection {
      background-color: #ffffff;
    }
  }

  .table-list .custom-table .custom-table-cell,
  .table-list .custom-table .custom-table-head {
    min-width: unset!important;
  }

  .table-list .custom-table .custom-table-cell {
    padding: 0.05rem 0.75rem;
  }

  @media (min-width: 1924px) {
    .table-list {
      .custom-table {
        width: 100%;
        .custom-table-heading {
          border-top: none;
        }
      }

      #head_box_workflowName, .cell_box_workflowName, #footer_box_workflowName {
        width: 35%!important;
      }

      #head_box_instanceId, .cell_box_instanceId, #footer_box_instanceId {
        width: 15%!important;
      }

      #head_box_createdAt, .cell_box_createdAt, #footer_box_createdAt,
      #head_box_updatedAt, .cell_box_updatedAt, #footer_box_updatedAt {
        width: 10%!important;
      }

      #head_box_versionNumber, .cell_box_versionNumber, #footer_box_versionNumber {
        width: 10%!important;
      }

      #head_box_entityNumber, .cell_box_entityNumber, #footer_box_entityNumber {
        width: 15%!important;
      }

      #head_box_action, .cell_box_action, #head_box_action_entityNumber {
        width: 5%!important;
      }
    }
  }
}

.in_progress_icon {
  color: #299B85;
}

.done_icon, .canceled_icon {
  color: $header-dark-background;
}

.failed_icon {
  color: red;
}

#workflow-list-page, #new-workflow-page, #workflow-view-page {
  .alert, .nav-pills {
    margin-bottom: 20px;
  }
}

.m-r-12 {
  margin-right: 12px;
}

.zi-3 {
  z-index: 3;
}

.zi-4 {
  z-index: 4;
}

.status-message-table {
  thead {
    th {
      border-top: none;
      font-weight: 700;
    }
  }
  tr {
    textarea,
    textarea:focus {
      background-color: #E9ECEF;
    }
  }
}

.status-message-details {
  overflow: hidden;
}

.text-c-danger {
  color: #994442;
}

.json-container {
  max-height: 650px; /* Adjust the height as needed */
  overflow-y: auto;
  overflow-x: auto;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  word-wrap: break-word; /* Ensure long text wraps */
}

.json-container::-webkit-scrollbar {
  width: 8px;
}

.json-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.json-container::-webkit-scrollbar-thumb {
  background: #888;
}

.json-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.variables-table {
  max-height: 600px;
  overflow-y: auto;
}

.actions-table {
  max-height: 585px;
  overflow-y: auto;
}

.table-container thead,
.table-container tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.selector {
  pointer-events: auto !important;
}

.align-items-start {
  align-items: start!important;
}

.display-none {
  display: none!important;
}

#dmn-form {
  .form-group {
    margin-bottom: 20px!important;
  }
}

#dmn-table-list, #wf-table-list {
  &.overflow-visible {
    .table-list,
    .infinite-list {
      overflow: visible!important;
    }
    .custom-table-heading {
      padding-right: 0px!important;
    }
  }

  .table-list {
    .custom-table-heading {
      padding-right: 16px;
    }
  }
}

.color-disabled-btn {
  color: $color-disabled-btn;
}

.vl {
  margin: -2px 8px 0px 8px;
}

.download-link {
  margin-left: -3px!important;
  i {
    margin-right: 6px;
  }
}
