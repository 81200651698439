$color-border-draft: #B9E7FC;

.custom-switch {
  .switch {
    input[type=checkbox] {
      width: auto;
        + .form-check-label {
            height: 100%;
            width: 100%;
            border: none;
            border-radius: 0;
            cursor: pointer;
            z-index: 0;
            top: 12px;
            padding: 0px 5px 0px 55px;
            color: $theme-heading-color;

            &:before {
              height: 20px;
              width: 45px;
              border-radius: 60px;
              border: 1px solid #e9eaec;
              background: $disabled;
            }

            &:after {
              top: 2px;
              left: 2px;
              height: 16px;
              width: 16px;
              box-shadow: none;
          }
        }
        &:checked + .form-check-label {
            &:after {
                left: 27px;
            }
        }
    }
    @each $value in $color-bt-name {
      $i: index($color-bt-name, $value);

      &.switch-#{$value} input[type=checkbox]:checked + .form-check-label:before {
          background: nth($color-bt-color,$i);
      }
  }
  }
}

#bf-policy-edit-page {
  background: $color-white;
  width: 100%;
  overflow: auto;
}

.t-0 {
  top: 0!important;
}

.justify-content-space-between {
  justify-content: space-between;
}

.white-space-nowrap {
  white-space: nowrap;
}

.d-contents {
  display: contents;
}

.draft-status-info {
  position: absolute;
  top: -30px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: $added-color!important;
}

.text-info-policy {
  color: $list-thumbnail-label-color;
  font-size: 12px;
}

.form-old-value,
.form-new-value {
  display: flex;
  &:before {
    width: 7px;
    font-size: 10px;
    padding-top: 5px;
  }
  .form-control-plaintext, .app-field-plaintext {
    padding: 5px 5px 0 5px;
    word-break: break-all;
  }
}

.form-old-value::before {
  content: '1';
}

.form-new-value::before {
  content: '2';
}

.invoice-total-row {
  .form-control-plaintext, .app-field-plaintext {
    padding-left: 0;
  }
}

.draft-border {
  border: 4px dashed $color-border-draft;
}

.fix-min-width-168 {
  min-width: 168px;
}

.custom-tabs-policy .tab-content:has(#policyPartialsView-tabpane-fzv) {
  padding: 0;
  background: none;
  box-shadow: none;
}

@media (max-width: 1200px) {
  .draft-status-info {
    top: auto;
    bottom: -30px;
  }
}
