$color-active-tab: #E7F1FF;
$color-border-header-toggle: rgba(0, 0, 0, 0.125);
$color-disabled-btn: #81D4FA;
$border-color-field: #ced4da;

/* Styles for edit product page */
.btn-link:hover, .btn-link:focus, .btn-link.focus {
  text-decoration: none;
}

.text-c-default {
  color: $theme-heading-color;
}

.theme-bg-passive {
  background: $light-color;
}

.text-field-tooltip {
  color: #1C7AA5;
}

.confirm-modal,
.product-modal {
  .modal-header,
  .modal-body,
  .modal-footer {
    padding: 20px;
  }

  .modal-body {
    p {
      margin-bottom: 20px;
    }
    .expression-ace-editor {
      border: 1px solid $border-color-field;
      height: 105px!important;
      width: auto!important;
      z-index: 0;
      position: relative;
      border-radius: 0.25rem;
      &.err {
        border-color: $errorText;
      }
    }
    .custom-select,
    .form-control {
      margin-top: 0;
    }
  }
  .modal-footer {
    .btn {
      margin: 0 0 0 10px;
    }
  }
}

.fullscreen-editor {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background: $color-white;
  overflow: hidden;
  .editor-preview {
    .ace_editor {
      height: 90vh!important;
      min-height: 600px;
      width: 100%!important;
    }
  }
}

.ace_editor.ace_autocomplete {
  min-width: 400px;
}

.product-modal.edit-field {
  .modal-dialog {
    margin-top: 5px;
  }
}

.ace_autocomplete {
  .ace_text-layer {
    width: calc(100% - 8px);
  }
  .ace_completion-meta {
    display: inline-block;
    text-align: right;
    opacity: 0.5;
    margin-left: 0.9em;
  }
  .ace_line > * {
    min-width: 0;
    flex: 0 0 auto;
  }
  .ace_line {
    display: flex;
    align-items: center;
    .ace_ {
      overflow: visible;
    }
    .ace_completion-spacer {
      flex: 1;
    }
  }
}

.i-m-b-30 {
  margin-bottom: 30px!important;
}

.disabled-link {
  pointer-events: none;
  opacity: 0.5;
}

.table-list {
  .custom-tooltip-box {
    max-width: 40%;
    white-space: normal;
    font-weight: 600;
    font-size: 13px;
    opacity: 1;
    box-shadow: 0px 0px 11px rgba(0, 0, 0, 0.1);
    border-radius: 50px;
    z-index: 99999;
    top: auto!important;
    margin: -10px 0 0 50px !important;
    &:after {
      border-top-color: $color-white !important;
      border-top-style: solid !important;
      border-top-width: 6px !important;
    }
  }
  .tooltip_description {
    text-overflow: ellipsis!important;
    white-space: nowrap!important;
    overflow: hidden!important;
    display: block;
  }
  .button-tooltip-top {
    margin: -70px 0 0 0!important;
    top: inherit!important;
  }
  .button-tooltip-left {
    margin: 0 -30px 0 0!important;
    top: inherit!important;
  }
}

#variable-data-list {
  .table-list {
    padding: 0px 15px;

    .custom-table .custom-table-heading {
      border-top: none;
    }
  }
}

#vwb-message-policy-info {
  .tab-content {
    padding: 0 !important;
    box-shadow: none;
  }
}

#product-page, #vwb-message-policy-info, #payment-config-page, #invite-organization-page {
  .product-wizard, .vwb-wizard, .payment-config-wizard {
    background: $color-white;
    margin-bottom: 30px;
    box-shadow: 0 5px 20px 0 rgba(0,0,0,0.12);
    box-shadow: 0 10px 20px 0 rgba(0,0,0,0.12);
    box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.3);

    a {
      position: relative;
      margin-right: 2px;
      margin-bottom: 0;
      border: none!important;
      border-radius: 0!important;
      box-shadow: none;
      background: 0 0!important;
      cursor: pointer;
      padding: 15px 25px;
      &:hover {
        border: none !important;
        color: $color-status-open;
        text-decoration: none;
        outline-style: none;
        background: 0 0 !important;
        border: none !important;
        * {
          color: $color-status-open;
        }
        &:after {
          background: $color-status-open;
        }
      }
      &:after {
        content: "";
        background: $color-white;
        height: 2px;
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 0;
        -webkit-transition: all 250ms ease 0s;
          transition: all 250ms ease 0s;
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
      }
    }
    a.complete {
      &:hover {
        &:after {
          background: $color-status-open;
        }
      }
      &:after {
        background: $added-color;
      }
    }
    a.disabled {
      cursor: not-allowed;
      pointer-events: all;
      &:hover {
        color: $color-status-open;
        opacity: 1;
        * {
          color: $color-status-open;
          opacity: 1;
        }
      }
      &:after {
        background: $color-white;
      }
    }
    a.active {
      color: $color-status-open;
      * {
        color: $color-status-open;
      }
      &:after {
        background: $color-status-open;
      }
    }
  }
  .tab-content {
    padding: 1rem 1.5rem 2.5rem 1.5rem;
    .btn-primary:focus {
      background-color: $primary-color;
      border-color: $primary-color;
      &:hover {
        background-color: $primary-color-hover;
        border-color: $primary-color-hover;
      }
    }
    #step-tabpane-factors {
      #csv-data-list {
        .export-button {
          margin: 0;
          padding: 10px 20px;
        }
        .table-list {
          padding: 20px 0;
          .custom-table-heading {
            span {
              color: $theme-font-color;
            }
          }
        }
      }
    }
    #step-tabpane-lookups {
      #csv-data-list {
        .export-button {
          max-width: 224px;
          margin-bottom: 0px;
        }
        .table-list {
          padding: 0px;
          .custom-table-head span {
            color: $theme-font-color;
          }
        }
      }
    }
    #step-tabpane-booking-funnel {
      *:disabled, .code-snippet:read-only {
        cursor: not-allowed;
        pointer-events: all;
      }
      .disabled {
        .ace_content {
          cursor: not-allowed;
          pointer-events: all;
        }
      }
      hr {
        margin-top: 12px !important;
      }
    }
  }
  .pcoded-header {
    margin: 0;
    width: auto!important;
    min-height: 21px;
    z-index: 1027;
    align-items: center;
    background: none!important;
    .dropdown {
      height: 21px;
      margin-right: 20px;
      align-items: center;
      display: flex;
      .dropdown-toggle {
        line-height: 21px;
        font-weight: bold;
        &:after {
          right: -20px!important;
        }
      }
      .dropdown-menu  {
        margin-top: 12px;
        .dropdown-item {
          padding: 10px 25px;
        }
      }
    }
    .dropdown.show:before {
      top: 28px;
    }
  }

  #editor-steps-config,
  #editor-custom-css,
  #editor-documents,
  #editor-produc-documents {
    border: 1px solid $list-thumbnail-border;
    width: 100%!important;
    min-height: 650px;
  }

  .app-field {
    .img-placeholder {
      width: auto;
      padding: 20px;
    }
  }
}

#product-list,
#payment-provider-card,
#product-overview {
  .label {
    border-radius: 15px;
    padding: 5px 16px;
  }
}

#product-item-list {
  height: calc(100vh - 390px);
  overflow: auto;
  align-content: baseline;
}

.date-picker-field {
  position: relative;
  .input-group > .form-control {
    padding-right: 40px;
  }
  .clear-date-button {
    position: absolute;
    right: 50px;
    top: 30px;
    margin: 0;
    padding: 10px;
    border: 0;
  }
}

.code-snippet, #code-snippet {
  font-family: monospace;
}

#object-data-list {
  .sticky-top {
    padding-top: 15px;
    padding-bottom: 15px;
    background: $color-white;
  }

  .card-header {
    cursor: pointer;
    border-radius: 0;
    background: none;
    border-top: 1px solid $color-border-header-toggle;
  }

  .card-body {
    min-height: auto;
    &.small-tables {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .accordion {
    .card-header {
      &.active-tab {
        background-color: $color-active-tab;
      }
      .toggle-panel {
        padding-left: 12px;
        padding-right: 12px;
      }
    }
  }

  .pt-5-object {
    padding-top: 5px;
  }

  .pb-2-object {
    padding-bottom: 2px;
  }

  .select-item--is-disabled {
    pointer-events: none;
    cursor: not-allowed;
  }
}

.rta-list {
  .rta-list-item {
    .rta__entity--selected {
      background: $rta-list-background;
      color: $color-status-solved;
    }
    div {
      display: flex;
      align-items: center;
      min-height: 36px;
      padding: 0px 11px 0px 11px;
    }
  }
  li:first-child div {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
  li:last-child div {
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }
}

#product-language-list {
  &.overflow-visible {
    .table-list,
    .infinite-list {
      overflow: visible!important;
    }
  }

  .table-list {
    padding: 25px 0px;
  }

  #head_box_language, .cell_box_language {
    width: 20% !important;
    min-width: 20%;
  }

  #head_box_language, .cell_box_language {
    width: 20% !important;
    min-width: 20%;
  }

  #head_box_language, .cell_box_language,
  #head_box_createdAt, .cell_box_createdAt,
  #head_box_updatedAt, .cell_box_updatedAt,
  #head_box_isActive, .cell_box_isActive,
  #head_box_translationStatus, .cell_box_translationStatus {
    width: 19% !important;
    min-width: 19%;
  }

  #head_box_actions, .cell_box_actions {
    padding-left: 20px;
    width: 5% !important;
    min-width: 5%;
  }

  .align-middle {
    position: relative;
    padding-bottom: 2px;
  }
}

.named-ranges-panel, .product-btn-panel {
  padding-right: 5px;
  .named-ranges {
    z-index: 100;
  }
}

.custom-icon, .btn-custom-icon {
  width: 18px !important;
  height: 18px !important;
  position: relative;
  font-size: 18px !important;
  cursor: pointer;
}

.btn-custom-icon {
  margin-right: 10px;
  vertical-align: middle;
}

div.named-range-table-wrapper {
  overflow-y: auto;
  thead.sticky-top {
    z-index: 10;
  }
}

#premimum-calc-data-list {
  .table-list {
    padding: 0px 15px;
    .cell_box_variableName,
    .cell_box_name,
    .cell_box_groupName,
    .cell_box_type,
    .cell_box_unit,
    .cell_box_itemType,
    .cell_box_visibility,
    .cell_box_pricePerUnitExpression {
      display: block;
      text-overflow: ellipsis;
      white-space: nowrap !important;
      overflow: hidden;
      color: #3F4D67;
    }
    .table-wrap{
      overflow-x: auto;
      .custom-table {
        width: 100%;
        min-width: 1400px;
        .custom-table-heading {
          border-top: none;
          align-items: center;
          display: flex;
          #head_box_pricePerUnitExpression {
            padding: 7px 12px;
          }
        }
      }
    }
  }
}

#premium-formula-search {
  input {
    width: 100px;
    padding: 0.5em;
  }

  .input-group-text {
    padding-left: 6px;
    padding-right: 6px;
  }
}

#premium-data-list {
  .sticky-top {
    padding-top: 15px;
    padding-bottom: 15px;
    background: $color-white;
  }
}

#bf-editor-tabs {
  .card-header {
    cursor: pointer;
    border-radius: 0;
    padding: 15px 20px 14px 20px!important;
    background: none;
    border-top: 1px solid $color-border-header-toggle;
    border-bottom: 0;
    margin: 0px!important;
    &.active-tab {
      background-color: $color-active-tab;
      border-bottom: 1px solid $color-border-header-toggle;
    }
    &.last-item {
      border-bottom: 1px solid $color-border-header-toggle;
    }
  }
  .collapse {
    margin: 0;
    padding: 0;
  }
  .card-body {
    min-height: auto;
  }
}

#product-page {
  .btn-outline-primary:disabled {
    opacity: 1;
    color: $color-disabled-btn;
    border-color: $color-disabled-btn;

    &:hover {
      background: none;
      border-color: $color-disabled-btn;
    }
  }
}


@media (max-width: 1550px) {
  .min-width-card-view {
    min-width: 50%;
  }
}

@media screen and (max-width: 576px) {
  .width-info-col {
    width: 100%;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 1415px) {
  .action-buttons {
    margin-bottom: 30px;
  }
}
