#report-list {
  &.overflow-visible {
    .table-list,
    .infinite-list {
      overflow: visible!important;
    }
  }

  #head_box_name,
  .cell_box_name {
    width: 30% !important;
    min-width: 30% !important;
  }

  #head_box_description,
  .cell_box_description {
    width: 42% !important;
    min-width: 42% !important;
  }

  #head_box_updatedAt,
  .cell_box_updatedAt {
    width: 150px !important;
    min-width: 150px !important;
  }

  #head_box_subscription,
  .cell_box_subscription {
    width: 90px !important;
    min-width: 90px !important;
  }

  .table-list .custom-table .custom-table-cell {
    padding: 0.8rem .75rem;
  }

  .cell_box_name {
    a {
      line-height: 16px;
    }
  }

  .cell_box_description {
    text-overflow: ellipsis;
    white-space: nowrap !important;
    overflow: hidden;
  }

  .switch {
    height: 24px;
    input[type=checkbox] {
      width: 30px;
      display: none;
    }
    input[type=checkbox] + .cr {
      top: 0;
    }
  }
}

#report-history-page {
  #head_box_name,
  .cell_box_name {
    width: 30% !important;
    min-width: 30% !important;
  }

  #head_box_action,
  .cell_box_action {
    width: 50% !important;
    min-width: 50% !important;
  }

  .table-list .custom-table .custom-table-cell {
    padding: 0.8rem .75rem;
  }
}

div#report-history-page {
  .field-selector {
    .select-item__control {
      min-height: 43px;
      .select-item__indicator {
        padding: 8px 12px;
      }
    }
  }
}
