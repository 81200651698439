#alert-list {
  &.overflow-visible {
    .table-list,
    .infinite-list {
      overflow: visible!important;
    }
  }

  #head_box_name,
  .cell_box_name {
    width: 20% !important;
    min-width: 20% !important;
  }

  #head_box_alertType,
  .cell_box_alertType,
  #head_box_updatedAt,
  .cell_box_updatedAt {
    width: 15% !important;
    min-width: 15% !important;
  }

  #head_box_description,
  .cell_box_description {
    width: 25% !important;
    min-width: 25% !important;
  }

  #head_box_category,
  .cell_box_category {
    width: 15% !important;
    min-width: 15% !important;
  }

  #head_box_subscription,
  .cell_box_subscription {
    width: 15% !important;
    min-width: 15% !important;
  }

  #head_box_action,
  .cell_box_action {
    width: 10% !important;
    min-width: 10% !important;
  }

  .table-list .custom-table {
    .custom-table-cell {
      padding: 0.8rem .75rem;
    }
  }

  .cell_box_name {
    a {
      line-height: 16px;
    }
  }

  .cell_box_description {
    text-overflow: ellipsis;
    white-space: nowrap !important;
    overflow: hidden;
  }

  .switch {
    height: 24px;
    input[type=checkbox] {
      width: 30px;
      display: none;
    }
    input[type=checkbox] + .cr {
      top: 0;
    }
  }
}

.alert-form-page {
  .channel-selector,
  .insurer-selector {
    .select-item__control {
      min-height: 43px;
      .select-item__indicator {
        padding: 8px 12px;
      }
    }

    &.is-invalid {
      .select-item__control {
        border-color: $errorText;
        &.select-item__control--is-focused {
          box-shadow: 0 0 0 $input-focus-width rgba($form-feedback-invalid-color, 0.25);
        }
      }
    }
  }

  .input-group {
    border-radius: 0.25rem;
    &.is-focused {
      box-shadow: $input-focus-box-shadow;
      .input-group-append {
        .input-group-text {
          border-color: $input-focus-border-color;
        }
      }
    }

    &.is-invalid-group ~ .invalid-feedback {
      display: block;
    }

    &.is-invalid-group {
      &.is-focused {
        box-shadow: 0 0 0 $input-focus-width rgba($form-feedback-invalid-color, 0.25);
      }
      .input-group-append {
        .input-group-text {
          border-color: $errorText;
        }
      }
    }

    .form-control:focus,
    .form-control.is-invalid:focus {
      box-shadow:none;
    }

    .form-control.is-invalid:focus + .input-group-append {
      .input-group-text {
        box-shadow:none;
      }
    }
  }
}

#history-list {
  #head_box_id,
  .cell_box_id {
    width: 5% !important;
    min-width: 5% !important;
  }

  .table-list .custom-table .custom-table-cell {
    padding: 0.8rem .75rem;
  }

  .cell_box_name {
    line-height: 16px;
  }

  #head_box_name,
  .cell_box_name,
  #head_box_description,
  .cell_box_description {
    width: 25% !important;
    min-width: 25% !important;
  }

  #head_box_results,
  .cell_box_results {
    width: 10% !important;
    min-width: 10% !important;
  }

  #head_box_triggeredAt,
  .cell_box_triggeredAt {
    width: 15% !important;
    min-width: 15% !important;
  }

  .cell_box_description {
    text-overflow: ellipsis;
    white-space: nowrap !important;
    overflow: hidden;
  }
}

.show_full_log_button {
  margin-left: 30px;
  font-size: 12px;
}

.error_alert {
  margin-top: 15px;
}

.error_alert:hover {
  color: #753433;
  text-decoration: underline;
}
