// =======================================
//     List of variables for layout
// =======================================
/*
    description Of variables for build for theme layouts
        1) menu-caption-color
            List of color for sidebar menu caption

        2) brand-background
            List of color for logo background

        3) header-dark-background
            List of color for Dark Header

        4) header-light-background
            List of color for light Header

        5) menu-dark-background
            List of color for Dark sidebar menu

        6) menu-light-background
            List of color for light sidebar menu

        7) menu-active-color
            List of color for Active item highliter

        8) menu-icon-color
            List of color for sidebar menu items icon colors
*/
$header-height: 70px;
$Menu-width: 264px;
$Menu-collapsed-width: 80px;

// color list for build layouts
// temp variables
$theme-color: linear-gradient(-135deg, #1de9b6 0%, #1dc4e9 100%);
$theme-color2: linear-gradient(-135deg, #899FD4 0%, #A389D4 100%);
$primary-color: #04a9f5;
$primary-color-hover: #0056b3;
$theme-blue-color: #2684FF;
$tab-nav-color: #007BFF;
$warning-color: #f4c22b;
$danger-color: #f44236;
$success-color: #1de9b6;
$added-color: #5CB85C;
$purple-color: #a389d4;
$info-color: #3ebfea;
$secondary-color: #748892;
$secondary-color-hover: #5a6268;
$default-color: #d6d6d6;
$light-color: #f2f2f2;
$dark-color: #37474f;
$inverse-color: $dark-color;
$theme-border: #eaeaea;
$cyan-color: #06c3c3;

// header
$brand-background: #3f4d67;
$header-dark-background: #3f4d67;
$header-dark-text-color: #fff;
$header-light-background: #fff;
$header-light-text-color: #3f4d67;

// Menu
$menu-caption-color: #14171d;
$menu-dark-background: #3f4d67;
$menu-dark-text-color: #a9b7d0;
$menu-light-background: #fff;
$menu-light-text-color: #3f4d67;
$menu-active-color: #04a9f5;

// dark layout colors
$dark-layout: #212224;
$dark-layout-font: #adb7be;

// table stripe
$table-stripe: #f4faff;
$button-hover: #e9ecef;
$pagination-filter: #333;

// disable
$disabled: #dee2e6;

$errorText: #DC3545;

// Menu icon
$menu-icon-color: $primary-color, #ff5252, #01a9ac ,#9575CD , #23b7e5, $warning-color;
// Header background
$color-header-name: blue, red, purple, lightblue, dark;
$color-header-color: $primary-color, #ff5252, #9575CD ,#23b7e5,lighten($dark-layout,7%);
// Menu background
$color-menu-name: blue, red, purple, lightblue, dark;
$color-menu-color: $primary-color, #ff5252, #9575CD ,#23b7e5,lighten($dark-layout,7%);
// Active background color
$color-active-name: blue, red, purple, lightblue, dark;
$color-active-color: $primary-color, #ff5252, #9575CD ,#23b7e5,lighten($dark-layout,7%);

 // Menu title color
$color-title-name: blue, red, purple, lightblue, dark;
$color-title-color: $primary-color, #ff5252, #9575CD ,#23b7e5,lighten($dark-layout,7%);

 // layout-6 background color
$layout-6-bg-color:#23b7e5;

$theme-font-family: 'Open Sans', sans-serif;
$theme-font-size: 14px;
$theme-background: #f4f7fa;
$theme-font-color: #888;
$theme-heading-color: #111;
$color-date: #6c757d;

$color-name: c-blue,c-red,c-green,c-yellow,c-purple,c-body,c-added,c-date,c-grey-btn,c-blue-link;
$color-color: $primary-color,$danger-color,$success-color,$warning-color,$purple-color,$theme-background,$added-color,$color-date,$theme-font-color,$tab-nav-color;
$color-bt-name: primary,danger,success,warning,info;
$color-bt-color: $primary-color,$danger-color,$success-color,$warning-color,$info-color;

// form-control variables
$form-bg: #f0f3f6;

$theme-colors: () !default;
$theme-colors: map-merge(("primary": $primary-color, "secondary": $secondary-color, "success": $success-color, "info": $info-color, "warning": $warning-color, "danger": $danger-color, "light": $light-color, "dark": $dark-color), $theme-colors); // Color contrast
$yiq-contrasted-threshold: 200 !default;
$yiq-dark-color: #37474f;
$yiq-light-color: #fff;
$theme-color-interval: 8% !default;
$alert-bg-level: -10 !default;
$alert-border-level: -9 !default;
$alert-color-level: 6 !default;

$color-white: #fff;
$search-border-color: #f1f1f1;
$user-search-border-color: #CED4DA;
$list-thumbnail-border: #CED4DA;
$list-thumbnail-label-color: #888888;
$color-link-setting: #495057;
$color-c-black: black;
$color-c-grey: #aaa;
$color-lite-gray: #cccccc;
$color-avatar-border: #8B9094;
$color-disabled: #6c757d;

// Status colors for tickets
$color-status-unsolved: #F44236;
$color-status-unassigned: #F4C22B;
$color-status-assigned-to-me: #5CB85C;
$color-status-open: #4285F4;
$color-status-blocked: #F44236;
$color-status-solved: #888;
$color-status-waiting: #9284CE;
$bg-color-btn-active: #F2F2F2;
$color-border-filter: #E4E4E4;
$bg-color-filter: #F6F6F6;
$bg-color-hover-filter: #D8EBF6;
$color-hover-border-filter: #4B9ED2;

//TextareaAutocomplete
$rta-list-background: #E0EBFD;

//thumbnails background
$thumbnail-block-1: linear-gradient(68.01deg, #9284CE 29.51%, #4285F4 87.79%);
$thumbnail-block-2: #1DC1E0;

// success screen button
$successButton: #0386C3;

//Red fail color
$fail-color: #E25041;

//Success color
$success-color: #2f794a;

//condtion button colors
$condtion-background: #F8F9FA;

//color subtitle
$color-subtitle: #6c757d;
$color-info-box: #E5E8EC;
$color-info-text: #33406F;
$color-active-ws: #009CD6;
$color-info-box-ws: #F3F6FD;
$color-info-text-ws: #4D5154;
$bg-contact-box: #F3F6FD;
$border-color-contact-box: #D5D9DE;
$color-disabled-text-ws: #9CA9B4;
$color-text-ws: #22282A;
$color-rangeslider-pr-ws: #009CD6;
$color-rangeslider-handle-ws: #ECF5FA;
$color-rangeslider-line-ws: #E5E8EC;
$color-approved: linear-gradient(265.16deg, #899FD4 6.64%, $purple-color 94.87%);
