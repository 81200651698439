#tariff-data-list {
  .table {
    td, th {
      white-space: normal;
      border-right: 1px solid $theme-border;
      &:last-child {
        border-right: none;
      }
    }

    th {
      text-align: center;
      font-size: 14px;
    }

    td {
      font-size: 13px;
      p.section-header strong {
        font-size: 16px;
      }
      p.section-item {
        padding-left: 2em;
      }
      p {
        strong {
          color: $theme-font-color;
          font-weight: 600;
        }
      }
    }
  }

  .table-responsive {
    border-bottom: 1px solid $theme-border;
    padding-bottom: 15px;
    .table-striped {
      margin: 0 -3px;
    }
  }
}

.tariff-form-page {
  .field-selector {
    .select-item__control {
      min-height: 43px;
      .select-item__indicator {
        padding: 8px 12px;
      }
    }

    &.is-invalid {
      .select-item__control {
        border-color: $errorText;
        &.select-item__control--is-focused {
          box-shadow: 0 0 0 $input-focus-width rgba($form-feedback-invalid-color, 0.25);
        }
      }
    }
  }

  .input-group {
    border-radius: 0.25rem;
    &.is-focused {
      box-shadow: $input-focus-box-shadow;
      .input-group-append {
        .input-group-text {
          border-color: $input-focus-border-color;
        }
      }
    }

    &.is-invalid-group ~ .invalid-feedback {
      display: block;
    }

    &.is-invalid-group {
      &.is-focused {
        box-shadow: 0 0 0 $input-focus-width rgba($form-feedback-invalid-color, 0.25);
      }
      .input-group-append {
        .input-group-text {
          border-color: $errorText;
        }
      }
    }

    .form-control:focus,
    .form-control.is-invalid:focus {
      box-shadow:none;
    }

    .form-control.is-invalid:focus + .input-group-append {
      .input-group-text {
        box-shadow:none;
      }
    }
  }

  .doc-list {
    height: calc(100vh - 650px);
  }

  .doc-list-inner {
    overflow: auto;
    width: 100%;
    height: calc(100% - 10px);
  }

  .doc-name,
  .doc-name h6 {
    width: calc(100% - 10px);
    text-overflow: ellipsis;
    overflow: hidden;
  }

  div.col.doc-selector {
    max-width: 20px;
    margin-left: 4px;

    input {
      width: 16px;
      height: 16px;
    }
  }

  .doc {
    border-bottom: 1px solid silver;
  }

  .doc-striped button {
    background-color: #EEEEEE;
  }

  .col-headline-expander {
    width: 30px;
    max-width: 30px;
    padding-right: 5px;
  }

  .col-headline-text {
    width: calc(100% - 30px);
    max-width: calc(100% - 30px);
    padding-left: 0;
  }

  .headline {
    margin-left: 10px;
    line-height: 120%;
  }

  .headline span {
    font-size: smaller;
  }

  .headline a {
    text-decoration: none;
    color: gray;
    line-height: 30%;
  }

  .doc-preview {
    height: calc(100vh - 100px);
  }
}

.doc-tab-close-icon {
  top: -3px;
  position: relative;
  cursor: pointer;

  i {
    top: 4px;
    position: relative;
  }

  i:hover {
    color: red;
  }
}

nav.doc-tabs {
  box-shadow: unset;

  a.nav-item.nav-link {
    text-transform: unset;
  }

  a.nav-item.nav-link {
    margin-bottom: 8px;
    padding: 4px 12px 4px 12px;
    border-radius: 30px;
    margin-left: 4px;
    margin-right: 4px;
    color: #333333;
    box-shadow: unset;
    font-size: smaller;
    height: fit-content;
    border-radius: 30px;
    background-color: #E6E6E6;
  }

   a.nav-item.nav-link.active {
    padding: 4px 3px 4px 12px;
    background-color: #04A9F5;
    color: white;
  }

   a.nav-item.nav-link:hover {
    background-color: #CCE5FF;
    color: #1C7AA5;
  }
}

#tariff-page {
  background-color: rgb(244, 247, 250);

  .tab-content {
    box-shadow: unset;
  }

  .tariff-comparison-pages,
  #tariffComparisonView-tabpane-performanceComparison {
    width: calc(100% + 15px);
    box-shadow: unset;
  }

  .card.mb-0 {
    border-style: none;
    margin-bottom: 0 !important;
  }

  .tab-content,
  .card-body {
    padding: 0;

    .card {
      padding-bottom: 0 !important;
      margin-bottom: 0 !important;
    }

    .doc-preview {
      height: calc(100vh - 335px);
    }

    .doc-preview-empty {
      height: calc(100vh - 245px);
    }
  }
}

.doc-preview-custom-toolbar {
  width: 80px;
  margin-left: 8px;
  margin-top: 4px;
  padding: 4px;

  i {
    position: relative;
    cursor: pointer;
    color: $primary-color;
  }
}

.modal.document-comparison {
  height: 100vh;

  .modal-header {
    button.close {
      margin-top: -5px;

    }
    .document-comparison-title {
      width: calc(100% - 20px);

      .modal-title {
        margin-top: 10px;
      }
    }
  }

  .doc-preview {
    height: calc(100vh - 150px);
  }

  .modal-dialog {
    width: calc(100% - 50px);
    max-width: calc(100% - 50px);
  }

  .form-control {
    margin-top: 0;
  }

  .btn > i {
    margin-right: 0;
  }
}

.export-dropdown {
  #dropdown-export {
    top: -1em;
    position: relative;
  }

  .dropdown-item {
    font-size: smaller;
  }
}

button.compare-tariffs {
  width: 220px;
}

.select-insurers .select-item__control {
  background-color: #f4f7fa;
}

.background-white {
  background-color: white;
}

.no-shadow {
  box-shadow: unset;
}
