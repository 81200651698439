$color-head: #888;
$color-text: #111;
$color-info-text: #6C757D;
$color-black-text: #000;
$danger-color-cl: #dc3545;
$dropdown-hover: #0387C4;
$dropdown-hover-open: #7976AA;
$dropdown-hover-in-progress: #18A6AF;
$dropdown-hover-closed: #C2C2C2;
$dropdown-hover-rejected: #5D6D75;

.custom-info-text {
  color: $color-info-text;
}

#claim-list {
  .overflow-visible {
    .table-list,
    .infinite-list {
      overflow: visible!important;
      z-index: 1;
    }
  }

  .table-list .custom-table .custom-table-cell {
    padding: 0.8rem .75rem;
  }

  .cell_box_claimNumber,
  .cell_box_policyCode {
    a {
      line-height: 16px;
    }
  }

  .cell_box_description {
    text-overflow: ellipsis;
    white-space: nowrap !important;
    overflow: hidden;
  }
}

.claim-documents-container {
  .table-list {
    padding: 0;
  }
}

#settlement-list {
  .custom-table-head {
    padding-top: 18px !important;
    padding-bottom: 17px !important;
    min-width: auto!important;
  }

  #head_box_reserve,
  #head_box_payment,
  #head_box_recourse,
  #head_box_remReserve {
    justify-content: flex-end;
    white-space: nowrap;
    display: inline-flex;
    gap: 10px;
  }

  .cell_box_reserve,
  .cell_box_payment,
  .cell_box_recourse,
  .cell_box_remReserve  {
    text-align: right;
  }

  #head_box_action {
    width: auto!important;
    min-width: auto!important;
  }

  .cell_box_action {
    width: 100%!important;
    min-width: auto !important;
    display: inline-flex;
    justify-content: flex-end;
    flex-wrap: wrap;
  }

  .table-list {
    padding: 0;
    .table-wrap {
      .custom-table {
        width: 100%;
        min-width: 685px;
      }
    }
  }
  .table {
    thead {
      tr {
        th {
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 21px;
        }
      }
    }
    tbody {
      max-height: 330px;
      tr {
        td {
          text-align: right;
        }
      }
    }
    tfoot {
      tr:first-of-type {
        td {
          border-top-width: 1px;
        }
      }
      tr {
        td {
          border-top-width: 0;
        }
        td.table-th {
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 21px;
        }
        td.border-t {
          border-top-width: 1px;
        }
      }
    }
  }
}

.btn-menu {
  .dropdown-menu {
    margin-top: 10px;
    padding: 10px 0px;
    min-width: 220px;
    a {
      padding: 8px 16px;
      min-height: 40px;
    }
  } & .show:before {
    content: ""!important;
  }
}

.regulation-modal {
  .form-group {
    .field-selector {
      .select-item__control .select-item__value-container {
        padding-left: 18px;
      }

      .select-item__indicators {
        svg {
          color: $color-link-setting;
        }
      }
    }
  }
}

#regulation-list {
  &.overflow-visible {
    .table-list,
    .infinite-list {
      overflow: visible!important;
    }
    .custom-table-heading {
      padding-right: 0px!important;
    }
  }

  .table-list .custom-table .custom-table-heading {
    border-top: none;
  }

  #head_box_amount,
  .cell_box_amount {
    text-align: right;
  }

  #head_box_amount {
    span {
      padding-right: 10px;
    }
  }

  .table-list {
    padding: 0;
    .table-wrap {
      .custom-table {
        width: 100%;
        min-width: 685px;
      }
    }
  }
  .table {
    thead {
      tr {
        th {
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 21px;
        }
      }
    }
    tbody {
      max-height: 330px;
      tr {
        td {
          text-align: right;
        }
      }
    }
    tfoot {
      tr:first-of-type {
        td {
          border-top-width: 1px;
        }
      }
      tr {
        td {
          border-top-width: 0;
        }
        td.table-th {
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 21px;
        }
        td.border-t {
          border-top-width: 1px;
        }
      }
    }
  }
}

.max-wid-945 {
  max-width: 945px;
}

.claim-payment-status {
  .payment-status-table {
    h6 {
      padding-left: 1rem;
      padding-right: 1rem;
      margin-bottom: 0;
    }

    div {
      h6 {
        display: flex;
        flex-wrap: wrap;
        align-content: center;
      }
    }
  }
  hr {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
  }
}

#audit-table {
  .table-list {
    padding: 0;
    .table-wrap{
      overflow-x: auto;
      .custom-table {
        width: 100%;
        min-width: 1000px;
        .cell_box_audit_value {
          white-space: nowrap !important;
          span {
            display: flex;
          }
          span.eclipsed {
            text-overflow: ellipsis;
            white-space: nowrap !important;
            overflow: hidden;
            display: block;
            cursor: pointer;
          }
        }
      }
    }
  }
}

#claim-document-list {
  .cell_box_documentId {
    text-overflow: ellipsis;
    white-space: nowrap !important;
    overflow: hidden;
    cursor: pointer;
  }
}

.sort-header {
  box-shadow: none;
  margin-left: -10px;
  cursor: pointer;

  &:before, &:after {
    border: 5px solid transparent;
    content: "";
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 10px 0 -10px
  }

  &:before {
    border-bottom-color: $theme-border;
    margin-top: -1px;
  }

  &:after {
    border-top-color: $theme-border;
    margin-top: 1px;
  }

  &.sort-header-desc {
    &:before {
      border-bottom-color: $theme-font-color;
    }
  }

  &.sort-header-asc {
    &:after {
      border-top-color: $theme-font-color;
      margin-top: 1px;
    }
  }
}

.rotate-90 {
  transform-origin: center;
  rotate: 90deg;
}

#reported-by-checkbox-label {
  color: $theme-font-color;
}

.app-field-select {
  .cl-select__control {
    background: $theme-background;
    .cl-select__value-container {
      padding: 11px 8px 11px 20px;
    }
  }
}

.app-field-control {
  .invalid-feedback {
    padding-top: 0.25em;
  }

  input[type="datetime-local"]::-webkit-calendar-picker-indicator {
    background: url("../../images/calendar_picker.svg") no-repeat;
  }

  input[type="date"]::-webkit-calendar-picker-indicator {
    background: url("../../images/calendar_picker.svg") no-repeat;
  }
}

#create-claim-form.was-validated {
  .form-control:valid,
  .form-control.is-valid {
    border-color: $input-border-color;
    padding-right: 10px;
    background-image: none;
  }

  .form-control:valid:focus {
    border-color: $input-focus-border-color;
    box-shadow: $input-focus-box-shadow;
  }

  .form-control.is-invalid {
    border-color: $form-feedback-invalid-color;
  }
  .form-control.is-invalid:focus {
    box-shadow: 0 0 0 $input-focus-width rgba($form-feedback-invalid-color, 0.25);
  }

  input[type="datetime-local"]:invalid::-webkit-calendar-picker-indicator {
    background: none;
  }

  input[type="datetime-local"]:invalid,
  input[type="datetime-local"].is-invalid {
    background-image: url("../../images/calendar_picker_error.svg");
    background-repeat: no-repeat;
  }
}

#head_box_deleteClaimButton {
  width: auto!important;
  min-width: auto!important;
}

.cell_box_deleteClaimButton {
  width: 100%!important;
  min-width: auto !important;
  display: inline-flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.claim-status-dropdown.editable {
  .dropdown-menu {
    padding: 12px 0 10px 0;
    width: 200px;
  }

  .dropdown-item {
    font-size: 14px;
    padding: 4px 20px;
  }

  .dropdown-toggle {
    font-size: 12px;
    margin: 0;
    border: none;
    border-radius: 50px;
    padding: 7px 16px;
    background-color: $primary-color;
    &:hover {
      background-color: $dropdown-hover;
    }
    &:after {
      margin-left: 10px !important;
      width: 0.6em;
    }
  }
}

.claim-status-dropdown.uneditable {

  .dropdown-toggle {
    font-size: 12px;
    margin: 0;
    border: none;
    border-radius: 50px;
    padding: 7px 16px;
    background-color: $primary-color;
    pointer-events: none;
    &:hover {
      background-color: $dropdown-hover;
    }
    &:after {
      display: none;
    }
  }
}

.claim-status-dropdown.open {
  .dropdown-toggle {
    background: $theme-color2;
    transition: none;
    &:hover {
      background: $dropdown-hover-open;
    }
  }
}

.claim-status-dropdown.in_progress {
  .dropdown-toggle {
    background: $theme-color;
    transition: none;
    &:hover {
      background: $dropdown-hover-in-progress;
    }
  }
}

.claim-status-dropdown.closed {
  .dropdown-toggle {
    color:$color-text;
    background-color: $light-color;
    &:hover {
      background-color: $dropdown-hover-closed;
    }
  }
}

.claim-status-dropdown.rejected {
  .dropdown-toggle {
    background-color: $secondary-color;
    &:hover {
      background-color: $dropdown-hover-rejected;
    }
  }
}

#custom-field-checkbox-label {
  color: $theme-font-color;
}

.invalid-field {
  color: $danger-color-cl
}

.claim-dropdown {
  display: flex;
  width: 468px;
  flex-direction: column;
  align-items: flex-start;
  font-size: 14px;
  font-family: Open Sans;
  line-height: 21px;

  p {
    color: var(--primary-gray, #888);
  }

  .form-check {
    padding: 2px 0!important;
    margin: 0!important;
    align-items: center;
    display: flex;
  }

  .form-check-label {
    padding: 0px!important;
    line-height: 18px;
  }

  .radio input[type=radio] + .form-check-label:before,
  .radio input[type=radio] + .cr:before {
    width: 18px;
    height: 18px;
  }

  .radio input[type=radio] + .form-check-label:after,
  .radio input[type=radio] + .cr:after {
    width: 10px;
    height: 10px;
    top: 6px;
    left: 4px;
  }

  .dropdown-toggle {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 11px 16px;
    background: var(--gray-05, #F4F7FA) !important;
    color: var(--gray-02, #495057) !important;
    border-radius: 4px;
    border: 1px solid var(--gray-04, #CED4DA);
    width: 468px;
    text-align: left;
  }

  .dropdown-toggle::after {
    display: none !important;
  }

  .dropdown-menu {
    max-height: 250px;
    overflow-y: auto;
    width: 468px;
  }

  i {
    width: 20px;
    height: 20px;
    color: #000000;
    align-items: left;
    margin: 0px;
  }

  .form-check-label {
    padding-top: 2px;
    padding-left: 5px;
    color: #111111;
  }

  .form-check-input {
    width: 15px;
    height: 15px;
  }
}

.md-claim-popup {
  .modal-body {
    padding: 0;
  }

  .md-saved-date-info {
    max-height: 350px;
    overflow-y: auto;
    padding: 0;
    margin: 0;
    overflow-x: hidden;
}

  p {
    margin-bottom: 8px;
  }

  .col {
    display: flex;
    padding: 16px 16px 32px 16px;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

   .dropdown-toggle {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 11px 16px;
   }

   .dropdown-item {
    font-size: 14px;
    padding: 5px 20px;
    &.active {
      background-color: $primary-color;
    }
   }

   .dropdown-toggle::after {
    display: none !important;
  }
}

#status-dropdown-header {
  padding-top: 8px;
}

#claim-partners {
  .table-list {
    padding: 10px;
    .custom-table-heading {
      border-top: none;
    }
  }
  .table-row-even {
    background: none;
  }

  #head_box_role,
  .cell_box_role,
  #head_box_name,
  .cell_box_name {
    width: 40% !important;
    min-width: 40% !important;
  }

  #head_box_action,
  .cell_box_action {
    width: 20% !important;
    min-width: 20% !important;
  }
}


#partners {
  .table-list {
    overflow: auto;
    padding: 0px;
    .custom-table {
      .custom-table-heading {
        border-top: none;
      }
      .infinite-list {
        width: 102%!important;
      }
    }
  }

  .custom-table .table-header,
  .custom-table .table-footer {
    bottom: 0px!important;
    z-index: 5;
    background-color: #ffffff;
  }

  .table-list .custom-table #head_box_selection,
  .table-list .custom-table .cell_box_selection,
  .table-list .custom-table #footer_box_selection {
    width: 42px !important;
    left: 0px;
    z-index: 5;
  }

  #head_box_selection, #footer_box_selection {
    background-color: #ffffff;
    color: #ffffff;
  }

  .table-row-even {
    .cell_box_selection {
      background-color: #f4faff;
    }
  }

  .table-row-odd {
    .cell_box_selection {
      background-color: #ffffff;
    }
  }

  .table-list .custom-table .custom-table-cell,
  .table-list .custom-table .custom-table-head {
    min-width: unset!important;
  }

  .table-list .custom-table .custom-table-cell {
    padding: 0.05rem 0.75rem;
  }

  #head_box_address,
  .cell_box_address,
  #footer_box_address,
  #head_box_displayName,
  .cell_box_displayName,
  #footer_box_displayName {
    width: 40% !important;
    min-width: 40% !important;
  }

  #head_box_action,
  .cell_box_action,
  #footer_box_action {
    width: 20% !important;
    min-width: 20% !important;
  }
}

#policy-object-picker {
  .icon-button {
    padding: 0px 12px 0px 0px;
    border: none;
    i {
      vertical-align: middle;
    }
  }
}

#policy-object-list {
  min-height: 594px;
  .table-list {
    overflow: auto;
    padding: 0px;
    min-height: 594px;
    .custom-table {
      width: max-content;
      .custom-table-heading {
        border-top: none;
      }
      .infinite-list {
        width: 102%!important;
      }
    }
  }

  .custom-table .table-header,
  .custom-table .table-footer {
    bottom: 0px!important;
    z-index: 5;
    background-color: #ffffff;
    width: max-content;
  }

  .table-list .custom-table #head_box_selection,
  .table-list .custom-table .cell_box_selection,
  .table-list .custom-table #footer_box_selection {
    width: 42px !important;
    left: 0px;
    z-index: 5;
  }

  #head_box_selection, #footer_box_selection {
    background-color: #ffffff;
    color: #ffffff;
  }

  .table-row-even {
    width: max-content!important;
    .cell_box_selection {
      background-color: #f4faff;
    }
  }

  .table-row-odd {
    width: max-content!important;
    .cell_box_selection {
      background-color: #ffffff;
    }
  }

  .table-list .custom-table .custom-table-cell,
  .table-list .custom-table .custom-table-head {
    min-width: unset!important;
  }

  .table-list .custom-table .custom-table-cell {
    padding: 0.05rem 0.75rem;
  }
}

